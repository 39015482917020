<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M11.1328 14.7875L12 17.3844L12.8672 14.7875L13.2234 13.7188L14.2922 13.3625L16.8844 12.5L14.2875 11.6328L13.2188 11.2766L12.8625 10.2078L12 7.61563L11.1328 10.2125L10.7766 11.2812L9.70781 11.6375L7.11563 12.5L9.7125 13.3672L10.7812 13.7234L11.1375 14.7922L11.1328 14.7875ZM9 15.5L3.55781 13.6859L0 12.5L3.55781 11.3141L9 9.5L10.8141 4.05781L12 0.5L13.1859 4.05781L15 9.5L20.4422 11.3141L24 12.5L20.4422 13.6859L15 15.5L13.1859 20.9422L12 24.5L10.8141 20.9422L9 15.5ZM2.53594 4.625L4.125 3.03594L4.92188 3.83281L7.17188 6.08281L7.96875 6.87969L6.375 8.46406L5.57812 7.66719L3.32812 5.42188L2.53125 4.625H2.53594ZM21.4688 4.625L20.6719 5.42188L18.4219 7.67188L17.625 8.46875L16.0359 6.875L16.8328 6.07812L19.0828 3.82812L19.8797 3.03125L21.4641 4.625H21.4688ZM20.6719 19.5781L21.4688 20.375L19.875 21.9641L19.0781 21.1672L16.8281 18.9172L16.0312 18.1203L17.625 16.5359L18.4219 17.3328L20.6719 19.5828V19.5781ZM2.53594 20.375L3.33281 19.5781L5.58281 17.3281L6.37969 16.5312L7.96406 18.125L7.16719 18.9219L4.91719 21.1719L4.12031 21.9688L2.53594 20.375Z"
  {fill}
/>
