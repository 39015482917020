<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M18.8906 6.30859C19.4648 6.52734 19.875 7.07422 19.875 7.67578V15.9609C19.875 16.3711 19.6836 16.7266 19.3281 16.9727C19 17.1914 18.5898 17.2734 18.207 17.1641C15.0352 16.1797 12.6289 17.5742 12.3555 17.7656C12.2461 17.8203 12.1367 17.875 11.9727 17.875C11.8086 17.875 11.6992 17.8203 11.5898 17.7656C11.4531 17.6836 8.99219 16.1523 5.73828 17.1641C5.35547 17.2734 4.94531 17.1914 4.61719 16.9727C4.28906 16.7266 4.125 16.3711 4.125 15.9609V7.67578C4.125 7.07422 4.50781 6.52734 5.08203 6.30859C6.3125 5.87109 7.51562 5.625 8.60938 5.625C10.2227 5.65234 11.3984 6.11719 12 6.39062C12.5742 6.11719 13.7227 5.65234 15.3633 5.65234C16.4297 5.65234 17.6328 5.87109 18.8906 6.30859ZM11.5625 16.7539V7.15625C11.0703 6.9375 10.0312 6.52734 8.60938 6.5C7.59766 6.5 6.50391 6.71875 5.35547 7.12891C5.13672 7.21094 5 7.42969 5 7.67578V15.9609C5 16.0703 5.05469 16.1797 5.13672 16.2617C5.24609 16.3438 5.35547 16.3438 5.49219 16.3164C6.39453 16.043 7.21484 15.9336 7.98047 15.9336C9.59375 15.9336 10.8789 16.4258 11.5625 16.7539ZM19 15.9609V7.67578C19 7.42969 18.8359 7.21094 18.6172 7.12891C17.4414 6.71875 16.3203 6.5 15.3633 6.5C13.9414 6.52734 12.9023 6.9375 12.4375 7.15625V16.7539C13.4492 16.2617 15.7188 15.4688 18.4805 16.3164C18.6172 16.3438 18.7266 16.3438 18.8359 16.2617C18.918 16.1797 19 16.0703 19 15.9609Z"
  {fill}
/>
