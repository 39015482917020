<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M13.9286 9.81836V2.96875H10.0714V9.81836V10.3926L9.75 10.8848L7.78125 14.125H16.1786L14.2098 10.8848L13.9286 10.3926V9.81836ZM15.8571 2.96875V9.81836L21 18.3496V20.0312V22H19.0714H4.92857H3V20.0312V18.3496L8.14286 9.81836V2.96875H7.82143H6.85714V1H7.82143H8.14286H10.0714H13.9286H15.8571H16.1786H17.1429V2.96875H16.1786H15.8571Z"
  {fill}
/>
