<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  fill-rule="evenodd"
  clip-rule="evenodd"
  d="M17.9 6.5H6.1V10.6667H-0.5V13.3333H6.1V17.5H17.9V13.3333H24.5V10.6667H17.9V6.5ZM8.9 14.8333V9.16667H15.1V14.8333H8.9Z"
  {fill}
/>
