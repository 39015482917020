<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M 12,1.5 12.208333,1.5833333 17.875,3.625 18.333333,3.7916667 V 4.25 10.166667 l 5.208334,1.875 L 24,12.208333 v 0.458334 6.75 V 19.875 L 23.583333,20.041667 17.916667,22.375 17.666667,22.5 17.375,22.375 12,20.166667 6.583333,22.375 6.333333,22.5 6.041667,22.375 0.375,20.041667 0,19.875 v -0.458333 -6.75 -0.458334 l 0.4166667,-0.166666 5.2500003,-1.875 V 4.25 3.7916667 L 6.083333,3.625 11.75,1.5833333 Z m 5,4.25 -4.333333,1.666667 v 4.291666 L 17,10.166667 Z M 11.333333,7.416667 7,5.75 v 4.416667 l 4.333333,1.541666 z M 12.666667,19 l 4.666666,1.916667 v -5.083334 l -4.666666,-1.666666 z m 6,1.625 4,-1.625 v -4.833333 l -4,1.666666 z m -7.333334,-6.458333 -4.666666,1.666666 v 5.083334 L 11.333333,19 Z M 1.3333333,19 5.333333,20.625 V 15.833333 L 1.3333333,14.166667 Z M 12,2.9166667 7.541667,4.5 12,6.208333 16.416667,4.5 Z M 6.333333,11.375 1.8333333,12.958333 6,14.666667 10.75,12.958333 Z M 22.125,12.958333 17.666667,11.375 l -4.458334,1.583333 4.75,1.708334 z"
  {fill}
/>
