<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M 20.25,3 H 18 V 0.5625 C 18,0.2812507 17.7188,0 17.437467,0 H 15.562533 C 15.2344,0 15,0.2812507 15,0.5625 V 3 H 9 V 0.5625 C 9,0.2812507 8.7187467,0 8.4374933,0 H 6.5625067 C 6.2343733,0 6,0.2812507 6,0.5625 V 3 H 3.75 C 2.4843747,3 1.5,4.0312533 1.5,5.25 v 16.5 C 1.5,23.0156 2.4843747,24 3.75,24 h 16.5 c 1.2188,0 2.25,-0.9844 2.25,-2.25 V 5.25 C 22.5,4.0312533 21.4688,3 20.25,3 Z M 19.9688,21.75 H 4.0312533 C 3.8437467,21.75 3.75,21.656267 3.75,21.4688 V 7.5 h 16.5 v 13.9688 c 0,0.187467 -0.140667,0.2812 -0.2812,0.2812 z"
  {fill}
/>
