<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M1.80282 3.52055L5.67136 6.49511C7.3615 5.15656 9.50235 4.15264 12.0188 4.15264C17.9531 4.15264 21.7089 9.72994 22.8357 12.4814C22.3099 13.7828 21.2207 15.6419 19.6808 17.2779L23.2864 20.0665L24 20.5871L22.9108 22L22.1972 21.4795L0.713615 4.93346L0 4.37573L1.0892 3L1.80282 3.52055ZM7.13615 7.64775L8.82629 8.91194C9.46479 8.39139 10.216 7.98239 11.0423 7.83366C11.3803 7.7593 11.6808 7.72211 12.0188 7.72211C14.6479 7.72211 16.8263 9.87867 16.8263 12.4814C16.8263 13.2622 16.6009 14.0059 16.3005 14.6751L18.216 16.1624C19.4178 14.9354 20.3192 13.5597 20.8451 12.4814C20.2817 11.3659 19.3052 9.84149 17.9531 8.5773C16.3756 7.05284 14.385 5.93738 12.0188 5.93738C10.1408 5.93738 8.52582 6.60665 7.13615 7.64775ZM14.8357 13.5225C14.9484 13.225 15.0235 12.8532 15.0235 12.4814C15.0235 10.8454 13.6714 9.50685 12.0188 9.50685C11.7934 9.50685 11.6056 9.54403 11.4178 9.58121L11.7183 11.18L14.8357 13.5225ZM16.6761 19.5832C15.3239 20.3268 13.7465 20.8102 12.0188 20.8102C6.04695 20.8102 2.32864 15.2701 1.20188 12.4814C1.57746 11.5519 2.25352 10.3249 3.19249 9.06067L4.61972 10.1761C3.98122 10.9941 3.49296 11.8121 3.15493 12.4814C3.71831 13.6341 4.69484 15.1585 6.04695 16.4227C7.62441 17.9472 9.61502 19.0254 12.0188 19.0254C13.1455 19.0254 14.1596 18.8023 15.1362 18.3562L16.6761 19.5832ZM13.446 17.0548C12.9953 17.1663 12.507 17.2407 11.9812 17.2407C9.35211 17.2407 7.17371 15.1213 7.17371 12.4814C7.17371 12.407 7.21127 12.2955 7.21127 12.184L9.31455 13.82C9.69014 14.6008 10.4413 15.1957 11.3427 15.3816L13.446 17.0548Z"
  {fill}
/>
