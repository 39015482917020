<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="m 11.999998,7.6875 -1.59372,-1.59375 -1.1719203,-1.125 c -0.75,-0.75 -1.78122,-1.21875 -2.90622,-1.21875 -2.250012,0 -4.078134,1.875 -4.078134,4.125 0,1.125 0.421872,2.15628 1.171872,2.90628 L 4.5937537,11.95314 4.6406257,12 h 1.640652 l 1.64058,-3.32814 0.65628,-1.26558 0.65622,1.26558 2.8125003,4.92186 2.29692,-3.46872 0.65622,-0.9375 0.60936,0.9375 1.26564,1.875 h 2.4375 l 0.09378,-0.04686 1.125,-1.17186 c 0.75,-0.75 1.21872,-1.78128 1.21872,-2.90628 0,-2.25 -1.875,-4.125 -4.125,-4.125 -1.07814,0 -2.15622,0.46875 -2.90622,1.21875 l -1.17192,1.125 z m 0,11.67186 L 16.312497,15 h 3.1875 L 13.546858,20.95314 11.999998,22.5 10.406278,20.95314 4.5000037,15 h 3.140634 z M 16.078137,13.5 l -0.234359,-0.32814 -0.843781,-1.3125 -2.390639,3.5625 -0.70308,1.03128 -0.5625,-1.07814 -2.7187803,-4.73436 -1.21872,2.48436 -0.23442,0.375 h -0.42186 -0.60936 -3.140634 L 1.8281257,12.375 C 0.6562537,11.20314 2.5e-6,9.5625 2.5e-6,7.875 2.5e-6,4.359372 2.8125037,1.5 6.3281377,1.5 c 1.6875,0 3.32814,0.703128 4.5000003,1.875 l 1.17186,1.125 1.125,-1.125 c 1.17186,-1.171872 2.8125,-1.875 4.499999,-1.875 3.51564,0 6.375,2.859372 6.375,6.375 0,1.6875 -0.70314,3.32814 -1.875,4.5 l -1.125,1.125 h -3.1875 -1.3125 z"
  {fill}
/>
