<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M12.75 21.7219V20.25H9.75L7.5 18V12.75H14.25L16.5 15H18L19.5 16.5H20.6531C20.9016 16.0219 21.1125 15.5203 21.2812 15H20.25L18 12.75V9H19.5V7.5H16.5V9.75H12.75H12V11.25H9V9.75L12.75 6V5.25H10.5V3.75L12 2.25C6.61406 2.25 2.25 6.61406 2.25 12C2.25 17.3859 6.61406 21.75 12 21.75C12.2531 21.75 12.5016 21.7406 12.75 21.7219ZM0 12C0 8.8174 1.26428 5.76516 3.51472 3.51472C5.76516 1.26428 8.8174 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12ZM8.77969 5.77969L7.27969 7.27969L6.75 7.80938L5.69063 6.75L6.22031 6.22031L7.72031 4.72031L8.25 4.19063L9.30938 5.25L8.77969 5.77969Z"
  {fill}
/>
