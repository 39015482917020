<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M 16.26564,4.59375 14.48436,2.8125 C 14.10936,2.484372 13.64064,2.25 13.125,2.25 c -1.03128,0 -1.875,0.890628 -1.875,1.921872 0,0.515628 0.1875,0.984378 0.51564,1.359378 L 13.59372,7.3125 14.25,7.96872 v 0.93756 2.95308 L 20.8125,5.25 h -2.95314 -0.9375 z M 17.85936,3 H 21.75 L 22.40628,3.703128 24,5.25 22.40628,6.84372 20.25,9 18,11.25 15.79686,13.45314 14.25,15 12.65628,13.45314 12,12.75 V 8.90628 L 11.34372,8.25 C 11.29686,8.29686 11.29686,8.29686 11.25,8.34372 L 6.89064,12.375 9.5625,21.75 H 16.875 18 V 24 H 16.875 1.125 0 V 21.75 H 1.125 7.21872 L 4.5,12.32814 4.3125,11.67186 4.828128,11.20314 9.70314,6.70314 C 9.75,6.65628 9.75,6.65628 9.79686,6.65628 9.28128,5.90625 9,5.0625 9,4.171872 9,1.875 10.82814,0 13.125,0 14.25,0 15.28128,0.46875 16.07814,1.21875 Z M 22.5,11.25 c 0,1.26564 -1.03128,2.25 -2.25,2.25 -0.65628,0 -1.26564,-0.28128 -1.6875,-0.70314 L 21.75,9.60936 c 0.46872,0.42192 0.75,0.98436 0.75,1.64064 z"
  {fill}
/>
