<script lang="ts">
  let className = '';

  export let uuid: string;
  export { className as class };
  export let width = 24;
  export let height = 24;

  export let entityId = '';
  export let fieldId = '';
</script>

<svg
  {width}
  {height}
  class={className}
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  role="img"
  data-contentful-entry-id={entityId}
  data-contentful-field-id={fieldId}
>
  <slot />
  <defs>
    <linearGradient id="mist-{uuid}">
      <stop offset="0%" stop-color="#34D399" />
      <stop offset="100%" stop-color="#FF6BFF" />
    </linearGradient>
    <linearGradient id="green-gradient-{uuid}">
      <stop offset="0%" stop-color="#C3FF62" />
      <stop offset="100%" stop-color="#1FF1A5" />
    </linearGradient>
    <linearGradient id="pink-gradient-{uuid}">
      <stop offset="0%" stop-color="#FF5555" />
      <stop offset="100%" stop-color="#B664FF" />
    </linearGradient>
    <linearGradient id="purple-ultraviolet-gradient-{uuid}">
      <stop offset="0%" stop-color="#B664FF" />
      <stop offset="100%" stop-color="#444CE7" />
    </linearGradient>
  </defs>
</svg>
