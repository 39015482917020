import add from './svg/add.svelte';
import aws from './svg/aws.svelte';
import aiBrain from './svg/ai-brain.svelte';
import arrowLeft from './svg/arrow-left.svelte';
import arrowRight from './svg/arrow-right.svelte';
import astronaut from './svg/astronaut.svelte';
import batch from './svg/batch.svelte';
import beaker from './svg/beaker.svelte';
import binary from './svg/binary.svelte';
import binaryCheck from './svg/binary-check.svelte';
import blog from './svg/blog.svelte';
import book from './svg/book.svelte';
import bookWithText from './svg/book-with-text.svelte';
import browserWindow from './svg/browser-window.svelte';
import calendar from './svg/calendar.svelte';
import calculator from './svg/calculator.svelte';
import certificate from './svg/certificate.svelte';
import chatBubble from './svg/chat-bubble.svelte';
import checkbox from './svg/checkbox.svelte';
import checkmark from './svg/checkmark.svelte';
import chevronDown from './svg/chevron-down.svelte';
import chevronUp from './svg/chevron-up.svelte';
import circuit from './svg/circuit.svelte';
import clappingHands from './svg/clapping-hands.svelte';
import cli from './svg/cli.svelte';
import cloud from './svg/cloud.svelte';
import close from './svg/close.svelte';
import cloudBits from './svg/cloud-bits.svelte';
import code from './svg/code.svelte';
import codeBranch from './svg/code-branch.svelte';
import cookie from './svg/cookie.svelte';
import copy from './svg/copy.svelte';
import core from './svg/core.svelte';
import creditCard from './svg/credit-card.svelte';
import customers from './svg/customers.svelte';
import crystalBall from './svg/crystal-ball.svelte';
import documentation from './svg/documentation.svelte';
import earth from './svg/earth.svelte';
import eda from './svg/eda.svelte';
import envelope from './svg/envelope.svelte';
import externalLink from './svg/external-link.svelte';
import followLink from './svg/follow-link.svelte';
import github from './svg/github.svelte';
import gitCommit from './svg/git-commit.svelte';
import gitBranch from './svg/git-branch.svelte';
import gitFork from './svg/git-fork.svelte';
import gitIssue from './svg/git-issue.svelte';
import gitPullRequest from './svg/git-pull-request.svelte';
import gitWatcher from './svg/git-watcher.svelte';
import glassBroken from './svg/glass-broken.svelte';
import google from './svg/google.svelte';
import handHoldingHeart from './svg/hand-holding-heart.svelte';
import handsHolding from './svg/hands-holding.svelte';
import heartbeat from './svg/heartbeat.svelte';
import hidden from './svg/hidden.svelte';
import hyphen from './svg/hyphen.svelte';
import lamp from './svg/lamp.svelte';
import laptop from './svg/laptop.svelte';
import linkedin from './svg/linkedin.svelte';
import mapMarker from './svg/map-marker.svelte';
import microsoft from './svg/microsoft.svelte';
import money from './svg/money.svelte';
import nav from './svg/nav.svelte';
import numberOneHand from './svg/number-one-hand.svelte';
import page from './svg/page.svelte';
import palmTree from './svg/palm-tree.svelte';
import paperPlane from './svg/paper-plane.svelte';
import play from './svg/play.svelte';
import pause from './svg/pause.svelte';
import people from './svg/people.svelte';
import pin from './svg/pin.svelte';
import questionBlock from './svg/question-block.svelte';
import quote from './svg/quote.svelte';
import rocket from './svg/rocket.svelte';
import saga from './svg/saga.svelte';
import schedule from './svg/schedule.svelte';
import schoolhouse from './svg/schoolhouse.svelte';
import server from './svg/server.svelte';
import shieldCheck from './svg/shield-check.svelte';
import shoppingBags from './svg/shopping-bags.svelte';
import slack from './svg/slack.svelte';
import spinner from './svg/spinner.svelte';
import speechBubble from './svg/speech-bubble.svelte';
import speech from './svg/speech.svelte';
import state from './svg/state.svelte';
import stroller from './svg/stroller.svelte';
import truck from './svg/truck.svelte';
import tutorial from './svg/tutorial.svelte';
import twitter from './svg/twitter.svelte';
import video from './svg/video.svelte';
import webinar from './svg/webinar.svelte';
import youtube from './svg/youtube.svelte';
import sparkleStar from './svg/sparkle-star.svelte';

export type IconName = keyof typeof icons;

export const icons = {
  add,
  aws,
  'ai-brain': aiBrain,
  'arrow-left': arrowLeft,
  'arrow-right': arrowRight,
  astronaut,
  batch,
  beaker,
  binary,
  'binary-check': binaryCheck,
  blog,
  book,
  'book-with-text': bookWithText,
  'browser-window': browserWindow,
  'chat-bubble': chatBubble,
  calculator,
  calendar,
  certificate,
  checkbox,
  checkmark,
  'chevron-down': chevronDown,
  'chevron-up': chevronUp,
  circuit,
  'clapping-hands': clappingHands,
  cli,
  cloud,
  close,
  'cloud-bits': cloudBits,
  code,
  'code-branch': codeBranch,
  cookie,
  copy,
  core,
  'credit-card': creditCard,
  customers,
  'crystal-ball': crystalBall,
  documentation,
  earth,
  eda,
  envelope,
  'external-link': externalLink,
  'follow-link': followLink,
  github,
  'git-branch': gitBranch,
  'git-commit': gitCommit,
  'git-fork': gitFork,
  'git-issue': gitIssue,
  'git-pull-request': gitPullRequest,
  'git-watcher': gitWatcher,
  'glass-broken': glassBroken,
  google,
  'hand-holding-heart': handHoldingHeart,
  'hands-holding': handsHolding,
  heartbeat,
  hidden,
  hyphen,
  lamp,
  laptop,
  linkedin,
  'map-marker': mapMarker,
  microsoft,
  money,
  nav,
  'number-one-hand': numberOneHand,
  page,
  'palm-tree': palmTree,
  'paper-plane': paperPlane,
  pause,
  people,
  play,
  pin,
  'question-block': questionBlock,
  quote,
  rocket,
  saga,
  'sparkle-star': sparkleStar,
  schedule,
  schoolhouse,
  server,
  'shield-check': shieldCheck,
  'shopping-bags': shoppingBags,
  slack,
  'speech-bubble': speechBubble,
  spinner,
  speech,
  state,
  stroller,
  truck,
  tutorial,
  twitter,
  video,
  webinar,
  youtube,
};

export const svgVariants = [
  'default',
  'mist',
  'green-gradient',
  'pink-gradient',
  'purple-ultraviolet-gradient',
] as const;

export type SvgVariant = (typeof svgVariants)[number];

export type Fill =
  | 'currentcolor'
  | `url('#${Exclude<SvgVariant, 'default'>}-${string}')`;

export const getFill = (variant: SvgVariant, name: string): Fill => {
  return variant === 'default' ? 'currentcolor' : `url('#${variant}-${name}')`;
};

export const iconNames = Object.keys(icons);
