<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M3.35714 3.35714V20.6429H20.6429V3.35714H3.35714ZM1 1H3.35714H20.6429H23V3.35714V20.6429V23H20.6429H3.35714H1V20.6429V3.35714V1ZM7.67857 8.46429C7.67857 6.99107 8.90625 5.71429 10.4286 5.71429H13.1786C14.8973 5.71429 16.3214 7.13839 16.3214 8.85714C16.3214 9.9375 15.683 10.9688 14.75 11.5089L13.1786 12.442V12.7857V13.9643H10.8214V12.7857V11.7545V11.067L11.4107 10.7232L13.5714 9.49554C13.817 9.34821 13.9643 9.10268 13.9643 8.85714C13.9643 8.41518 13.6205 8.12054 13.1786 8.12054H10.4286C10.183 8.12054 10.0357 8.26786 10.0357 8.51339V8.80804H7.67857V8.46429ZM10.8214 15.9286H13.1786V18.2857H10.8214V15.9286ZM4.14286 5.32143C4.14286 4.68304 4.63393 4.14286 5.32143 4.14286C5.95982 4.14286 6.5 4.68304 6.5 5.32143C6.5 6.00893 5.95982 6.5 5.32143 6.5C4.63393 6.5 4.14286 6.00893 4.14286 5.32143ZM18.6786 4.14286C19.317 4.14286 19.8571 4.68304 19.8571 5.32143C19.8571 6.00893 19.317 6.5 18.6786 6.5C17.9911 6.5 17.5 6.00893 17.5 5.32143C17.5 4.68304 17.9911 4.14286 18.6786 4.14286ZM4.14286 18.6786C4.14286 18.0402 4.63393 17.5 5.32143 17.5C5.95982 17.5 6.5 18.0402 6.5 18.6786C6.5 19.3661 5.95982 19.8571 5.32143 19.8571C4.63393 19.8571 4.14286 19.3661 4.14286 18.6786ZM18.6786 17.5C19.317 17.5 19.8571 18.0402 19.8571 18.6786C19.8571 19.3661 19.317 19.8571 18.6786 19.8571C17.9911 19.8571 17.5 19.3661 17.5 18.6786C17.5 18.0402 17.9911 17.5 18.6786 17.5Z"
  {fill}
/>
