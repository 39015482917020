<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M4.5 10.875H5.625H18.375H19.5V13.125H18.375H5.625H4.5V10.875Z"
  {fill}
/>
