<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M14.25 4H13.5V5.5H14.25H17.4406L10.4688 12.4688L9.9375 13L11 14.0594L11.5312 13.5281L18.5 6.55938V9.75V10.5H20V9.75V4.75V4H19.25H14.25ZM4.75 5H4V5.75V19.25V20H4.75H18.25H19V19.25V13.75V13H17.5V13.75V18.5H5.5V6.5H10.25H11V5H10.25H4.75Z"
  {fill}
/>
