<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M7.25 2.25H4.25V5.25H7.25V2.25ZM9.5 0V7.5H6.875V9C6.875 10.0781 7.67188 10.875 8.75 10.875H16.25C17.2813 10.875 18.125 10.0781 18.125 9V7.5H15.5V0H23V7.5H20.375V9C20.375 11.2969 18.5 13.125 16.25 13.125H13.625V16.5H16.25V24H8.75V16.5H11.375V13.125H8.75C6.45313 13.125 4.625 11.2969 4.625 9V7.5H2V0H9.5ZM20.75 2.25H17.75V5.25H20.75V2.25ZM11 18.75V21.75H14V18.75H11Z"
  {fill}
/>
