<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M5.11719 9.70703C5.20508 9.58984 5.32227 9.53125 5.46875 9.53125C5.55664 9.53125 5.67383 9.56055 5.76172 9.64844L11.5332 14.9512L17.334 9.64844C17.5098 9.47266 17.8027 9.47266 17.9785 9.67773C18.1543 9.85352 18.1543 10.1465 17.9492 10.3223L11.8555 15.9473C11.6797 16.123 11.416 16.123 11.2402 15.9473L5.14648 10.3223C4.94141 10.1758 4.94141 9.88281 5.11719 9.70703Z"
  {fill}
/>
