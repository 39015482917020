<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="m 7.333333,5.3333415 v 2.66665 h 4 v -2.66665 c 0,-1.083334 -0.916693,-2 -2,-2 -1.125013,0 -2,0.916666 -2,2 z m -2,2.66665 v -2.66665 c 0,-2.208331 1.79168,-4 4,-4 2.20832,0 4,1.791669 4,4 v 2.66665 h 3.333334 2 v 2 0.6666675 h -2 V 9.9999915 H 2 v 9.3333335 h 4.666667 v 2 H 2 0 v -2 -9.3333335 -2 H 2 Z M 24,11.999992 V 22.666658 H 8 V 11.999992 Z M 13.333333,15.333326 V 14.666659 H 12 v 0.666667 0.666666 c 0,2.208373 1.79168,3.999999 4,3.999999 2.20832,0 4,-1.791626 4,-3.999999 v -0.666666 -0.666667 h -1.333333 v 0.666667 0.666666 c 0,1.499999 -1.20832,2.666666 -2.666667,2.666666 -1.5,0 -2.666667,-1.166667 -2.666667,-2.666666 z"
  {fill}
/>
