<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M18.2437 2.25H21.5531L14.325 10.5094L22.8281 21.75H16.1719L10.9547 14.9344L4.99216 21.75H1.6781L9.40779 12.9141L1.25623 2.25H8.08123L12.7922 8.47969L18.2437 2.25ZM17.0812 19.7719H18.914L7.08279 4.125H5.11404L17.0812 19.7719Z"
  {fill}
/>
