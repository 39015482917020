<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="m 8.4,12.000012 c 2.624983,0 4.8,-2.1375091 4.8,-4.8000211 0,-2.6250033 -2.175017,-4.8000027 -4.8,-4.8000027 -2.662498,0 -4.8,2.1749994 -4.8,4.8000027 0,2.662512 2.137502,4.8000211 4.8,4.8000211 z m 0,-7.800021 c 1.649966,0 3,1.35 3,2.9999999 0,1.687495 -1.350034,3.0000211 -3,3.0000211 -1.687502,0 -3,-1.3125261 -3,-3.0000211 0,-1.6499999 1.312498,-2.9999999 3,-2.9999999 z m 1.875017,9.600021 H 6.487502 C 2.8875017,13.800012 0,16.724995 0,20.324995 0,21.03752 0.5624997,21.600012 1.2750034,21.600012 H 15.487474 C 16.2,21.600012 16.8,21.03752 16.8,20.324995 c 0,-3.6 -2.924983,-6.524983 -6.524983,-6.524983 z m -8.475017,6 c 0.2624983,-2.325052 2.25,-4.2 4.687502,-4.2 h 3.787515 c 2.437509,0 4.387474,1.874948 4.687474,4.2 z m 16.162491,-5.4 H 15.187474 C 16.912526,15.824995 18,17.962503 18,20.324995 c 0,0.487474 -0.150034,0.9 -0.375017,1.275017 H 22.8 c 0.637509,0 1.2,-0.525052 1.2,-1.2 0,-3.3 -2.7,-6 -6.037509,-6 z m -1.762491,-2.4 c 2.287474,0 4.2,-1.875017 4.2,-4.2000211 0,-2.2875016 -1.912526,-4.1999999 -4.2,-4.1999999 -0.975017,0 -1.837509,0.3374948 -2.512526,0.8624983 C 14.1,5.2874858 14.4,6.2249876 14.4,7.1999909 c 0,1.35 -0.449966,2.587495 -1.2,3.5625121 0.750034,0.787474 1.8,1.237509 3,1.237509 z"
  {fill}
/>
