<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M0 13.5L2.60625 14.6156L9 17.3578V17.625V20.625V23.25L11.25 24L14.6859 19.7953L18.8531 21.5812L21 22.5L21.3094 20.1844L23.6297 2.78906L24 0L21.5438 1.37812L2.47031 12.1125L0 13.5ZM19.1625 19.2656L12.5812 16.4437L20.8547 6.57187L19.1625 19.2656ZM10.4203 15.5203L5.07656 13.2281L18.8203 5.49375L10.4203 15.5203Z"
  {fill}
/>
