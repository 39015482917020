<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<g clip-path="url(#clip0_1786_8418)">
  <path
    d="M5.91188 14.7703C5.91188 15.9844 4.92 16.9762 3.70594 16.9762C2.49188 16.9762 1.5 15.9844 1.5 14.7703C1.5 13.5563 2.49188 12.5644 3.70594 12.5644H5.91188V14.7703ZM7.02375 14.7703C7.02375 13.5563 8.01562 12.5644 9.22969 12.5644C10.4437 12.5644 11.4356 13.5563 11.4356 14.7703V20.2941C11.4356 21.5081 10.4437 22.5 9.22969 22.5C8.01562 22.5 7.02375 21.5081 7.02375 20.2941V14.7703ZM9.22969 5.91188C8.01562 5.91188 7.02375 4.92 7.02375 3.70594C7.02375 2.49187 8.01562 1.5 9.22969 1.5C10.4437 1.5 11.4356 2.49187 11.4356 3.70594V5.91188H9.22969ZM9.22969 7.02375C10.4437 7.02375 11.4356 8.01562 11.4356 9.22969C11.4356 10.4437 10.4437 11.4356 9.22969 11.4356H3.70594C2.49188 11.4356 1.5 10.4437 1.5 9.22969C1.5 8.01562 2.49188 7.02375 3.70594 7.02375H9.22969ZM18.0881 9.22969C18.0881 8.01562 19.08 7.02375 20.2941 7.02375C21.5081 7.02375 22.5 8.01562 22.5 9.22969C22.5 10.4437 21.5081 11.4356 20.2941 11.4356H18.0881V9.22969ZM16.9762 9.22969C16.9762 10.4437 15.9844 11.4356 14.7703 11.4356C13.5563 11.4356 12.5644 10.4437 12.5644 9.22969V3.70594C12.5644 2.49187 13.5563 1.5 14.7703 1.5C15.9844 1.5 16.9762 2.49187 16.9762 3.70594V9.22969ZM14.7703 18.0881C15.9844 18.0881 16.9762 19.08 16.9762 20.2941C16.9762 21.5081 15.9844 22.5 14.7703 22.5C13.5563 22.5 12.5644 21.5081 12.5644 20.2941V18.0881H14.7703ZM14.7703 16.9762C13.5563 16.9762 12.5644 15.9844 12.5644 14.7703C12.5644 13.5563 13.5563 12.5644 14.7703 12.5644H20.2941C21.5081 12.5644 22.5 13.5563 22.5 14.7703C22.5 15.9844 21.5081 16.9762 20.2941 16.9762H14.7703Z"
    {fill}
  />
</g>
<defs>
  <clipPath id="clip0_1786_8418">
    <rect width="21" height="24" fill="white" transform="translate(1.5)" />
  </clipPath>
</defs>
