<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="m 0,1.5 h 24 v 6 2.25 0.75 9.75 2.25 H 21.75 2.25 0 V 20.25 10.5 9.75 7.5 Z m 21.75,9 H 2.25 v 9.75 h 19.5 z M 6,4.5 H 3 v 3 h 3 z m 1.5,0 v 3 h 3 v -3 z m 7.5,0 h -3 v 3 h 3 z"
  {fill}
/>
