<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M2.25 7.06875L12 13.7719L21.75 7.06875V5.25H2.25V7.06875ZM21.75 9.79688L12 16.5L2.25 9.79688V18.75H21.75V9.79688ZM0 18.75V8.25V5.25V3H2.25H21.75H24V5.25V8.25V18.75V21H21.75H2.25H0V18.75Z"
  {fill}
/>
