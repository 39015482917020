<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="m 10.149973,1.6999985 -5.699974,3.0000035 -2.7999998,5.850002 1.1000018,6.4 4.649984,4.499992 6.4,0.900023 5.700023,-3.049996 2.799969,-5.800046 V 13.349973 C 19.450027,12.600015 17.250011,10.299977 16.600015,7.399985 13.700023,6.749989 11.450027,4.549998 10.650027,1.7499977 Z M 18.00003,5.99997 C 18.149973,9.149989 20.600015,11.650011 23.650011,12 L 24,13.700023 20.699977,20.499992 14.099992,24 6.650027,22.949973 1.2999985,17.750004 0,10.350019 3.249999,3.550001 9.850027,0 12,0.3500008 C 12.349989,3.399997 14.850011,5.850027 18.00003,5.99997 Z M 7.59997,16.8 8.8,15.59997 10.00003,16.8 8.8,18.00003 Z M 7.2,10.00003 5.99997,8.8 7.2,7.59997 8.40003,8.8 Z M 12.39997,12 13.6,10.79997 14.80003,12 13.6,13.20003 Z M 16.8,18.00003 15.59997,16.8 16.8,15.59997 18.00003,16.8 Z"
  {fill}
/>
