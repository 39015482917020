<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<g clip-path="url(#clip0_2449_10932)">
  <path
    d="M3.75 2.25V5.25H6.75V2.25H3.75ZM1.5 0H3.75H6.75H9V2.25V5.25V7.5H6.75H6.375V12C7.31719 11.2922 8.48438 10.875 9.75 10.875H14.25C16.1156 10.875 17.625 9.36562 17.625 7.5H17.25H15V5.25V2.25V0H17.25H20.25H22.5V2.25V5.25V7.5H20.25H19.875C19.875 10.6078 17.3578 13.125 14.25 13.125H9.75C7.88438 13.125 6.375 14.6344 6.375 16.5H6.75H9V18.75V21.75V24H6.75H3.75H1.5V21.75V18.75V16.5H3.75H4.125V7.5H3.75H1.5V5.25V2.25V0ZM6.75 18.75H3.75V21.75H6.75V18.75ZM20.25 2.25H17.25V5.25H20.25V2.25Z"
    {fill}
  />
</g>
<defs>
  <clipPath id="clip0_2449_10932">
    <rect width="24" height="24" fill="white" transform="translate(1.5)" />
  </clipPath>
</defs>
