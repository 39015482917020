<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M 23.498438,5.81639 C 23.222439,4.7078 22.409253,3.8347 21.376599,3.53841 19.504978,3 12.000023,3 12.000023,3 c 0,0 -7.5050005,0 -9.3766687,0.53841 C 1.590775,3.83475 0.7775235,4.7078 0.5015053,5.81639 0,7.82578 0,12.0182 0,12.0182 c 0,0 0,4.1924 0.5015053,6.2018 0.2760182,1.1086 1.0892697,1.9453 2.121849,2.2416 C 4.4950225,21 12.000023,21 12.000023,21 c 0,0 7.504955,0 9.376576,-0.5384 1.032654,-0.2963 1.84584,-1.133 2.121839,-2.2416 C 24,16.2106 24,12.0182 24,12.0182 c 0,0 0,-4.19242 -0.501562,-6.20181 z M 9.545466,15.8246 V 8.2118 l 6.272706,3.8065 z"
  {fill}
/>
