<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<g clip-path="url(#clip0_1786_8354)">
  <path
    d="M20.25 15.75H9.75V2.25H17.0672L20.25 5.43281V15.75ZM22.5 4.5L18 0H9.75H7.5V2.25V15.75V18H9.75H20.25H22.5V15.75V4.5ZM3.75 6H1.5V8.25V21.75V24H3.75H14.25H16.5V21.75V19.5H14.25V21.75H3.75V8.25H6V6H3.75Z"
    {fill}
  />
</g>
<defs>
  <clipPath id="clip0_1786_8354">
    <rect width="21" height="24" fill="white" transform="translate(1.5)" />
  </clipPath>
</defs>
