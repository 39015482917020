<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M0 0.5H10.0312V10.5781H0V0.5ZM10.9219 0.5H21V10.5781H10.9219V0.5ZM0 11.4688H10.0312V21.5H0V11.4688ZM10.9219 11.4688H21V21.5H10.9219V11.4688Z"
  {fill}
/>
