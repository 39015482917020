<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M5.25 2.25H2.25V5.25H5.25V2.25ZM7.5 0V7.5H4.875V16.5H7.5V24H0V16.5H2.625V7.5H0V0H7.5ZM2.25 18.75V21.75H5.25V18.75H2.25ZM18.75 18.75V21.75H21.75V18.75H18.75ZM16.5 16.5H19.125V9C19.125 7.17188 17.5781 5.625 15.75 5.625H15V9H13.5L9 4.5L13.5 0H15V3.375H15.75C18.8438 3.375 21.375 5.90625 21.375 9V16.5H24V24H16.5V16.5Z"
  {fill}
/>
