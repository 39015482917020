<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="m 18.759894,8.2269146 c 0,-2.3941591 -1.314424,-4.6005133 -3.379947,-5.8210497 -2.112467,-1.2205363 -4.694371,-1.2205363 -6.7598937,0 -2.1124667,1.2205364 -3.3799468,3.4268906 -3.3799468,5.8210497 0,2.4410724 1.2674801,4.6474264 3.3799468,5.8679634 2.0655227,1.220536 4.6474267,1.220536 6.7598937,0 2.065523,-1.220537 3.379947,-3.426891 3.379947,-5.8679634 z m -15.0219861,0 c 0,-2.9105399 1.5491423,-5.6332748 4.1310461,-7.1354734 2.53496,-1.45525493 5.680189,-1.45525493 8.262092,0 2.53496,1.5021986 4.131047,4.2249335 4.131047,7.1354734 0,2.9574534 -1.596087,5.6801884 -4.131047,7.1823874 -2.581903,1.455255 -5.727132,1.455255 -8.262092,0 C 5.2870502,13.907103 3.7379079,11.184368 3.7379079,8.2269146 Z M 2.9868086,5.2224873 V 11.231312 H 1.48461 V 5.2224873 Z m 19.5285814,0 V 11.231312 H 21.013192 V 5.2224873 Z M 3.7379079,14.235709 h 0.5633245 c 0.4224933,0.563324 0.8919304,1.079705 1.4552549,1.502199 H 4.9115006 L 3.3623583,22.497801 H 5.9912058 V 18.742305 H 18.008795 v 3.755496 h 2.581903 l -1.549142,-6.759893 h -0.844987 c 0.563325,-0.422494 1.032762,-0.938875 1.455255,-1.502199 h 0.563325 L 22.139841,22.497801 22.468447,24 H 1.48461 L 1.8132159,22.497801 Z M 16.506596,20.244503 H 7.4934044 v 2.253298 H 8.995603 v -1.502198 h 1.502199 v 1.502198 h 3.004397 v -1.502198 h 1.502198 v 1.502198 h 1.502199 z M 12.7511,11.982411 c 2.065523,0 3.755496,-1.64303 3.755496,-3.7554964 V 7.3819279 L 15.098285,5.9735866 H 8.8547719 L 7.4934044,7.3819279 v 0.8449867 c 0,2.1124664 1.6430297,3.7554964 3.7554966,3.7554964 z M 5.9912058,6.724716 8.2445037,4.471388 h 7.5109933 l 2.253298,2.253328 v 1.5021986 c 0,2.9105094 -2.394129,5.2576954 -5.257695,5.2576954 h -1.502199 c -2.9105099,0 -5.2576952,-2.347186 -5.2576952,-5.2576954 z m 4.5065962,0 0.610268,1.26748 1.26748,0.6102682 L 11.10807,9.2596761 10.497802,10.480212 9.8405897,9.2596761 8.6200533,8.6024642 9.8405897,7.992196 Z"
  {fill}
/>
