<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M8.7 3H9.525H11.45H12.275V3.84293V9.18146H13.375H14.2V10.8673H13.375H11.45H9.525H8.7V9.18146H9.525H10.625V4.68585H9.525H8.7V3ZM1 13.1151H1.825H3.75H4.575V13.958V19.2966H5.675H6.5V20.9824H5.675H3.75H1.825H1V19.2966H1.825H2.925V14.801H1.825H1V13.1151ZM1.825 3H6.775H7.6V3.84293V10.0244V10.8673H6.775H1.825H1V10.0244V3.84293V3H1.825ZM2.65 9.18146H5.95V4.68585H2.65V9.18146ZM7.6 13.1151H8.425H12.6531C12.3781 13.642 12.2062 14.2039 12.0687 14.801H9.25V19.2966H10.9H12.9625C13.3062 19.8234 13.7187 20.3151 14.2 20.7015V20.9824H13.375H8.425H7.6V20.1395V13.958V13.1151ZM13.1 15.9249C13.1 14.1337 14.0281 12.4829 15.575 11.5698C17.0875 10.6566 18.9781 10.6566 20.525 11.5698C22.0375 12.4829 23 14.1337 23 15.9249C23 17.7512 22.0375 19.402 20.525 20.3151C18.9781 21.2283 17.0875 21.2283 15.575 20.3151C14.0281 19.402 13.1 17.7512 13.1 15.9249ZM20.7312 14.801L19.975 14.0283L19.5625 14.4146L17.5 16.5571L16.5031 15.5385L16.125 15.1522L15.3344 15.9249L15.7125 16.3463L17.0875 17.7512L17.5 18.1376L17.8781 17.7512L20.3531 15.2224L20.7312 14.801Z"
  {fill}
/>
