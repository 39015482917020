<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M5.375 16.75C7.125 18.4583 9.33333 19.6667 12 19.6667C14.625 19.6667 16.8333 18.4583 18.5833 16.75C20.0833 15.3333 21.1667 13.625 21.7917 12.3333C21.1667 11.0833 20.0833 9.375 18.5833 7.95833C16.8333 6.25 14.625 5 12 5C9.33333 5 7.125 6.25 5.375 7.95833C3.875 9.375 2.79167 11.0833 2.16667 12.3333C2.79167 13.625 3.875 15.3333 5.375 16.75ZM12 21.6667C5.375 21.6667 1.25 15.4583 0 12.3333C1.25 9.25 5.375 3 12 3C18.5833 3 22.75 9.25 24 12.3333C22.75 15.4583 18.5833 21.6667 12 21.6667ZM12 15.6667C13.8333 15.6667 15.3333 14.2083 15.3333 12.3333C15.3333 10.5 13.8333 9 12 9C11.75 9 11.5417 9.04167 11.3333 9.08333L12 12.3333L8.70833 11.7083C8.66667 11.9167 8.66667 12.125 8.66667 12.3333C8.66667 14.2083 10.125 15.6667 12 15.6667ZM6.66667 12.3333C6.66667 12 6.66667 11.625 6.75 11.2917C7.16667 9.16667 8.83333 7.54167 10.9167 7.125C11.2917 7.04167 11.625 7 12 7C14.9167 7 17.3333 9.41667 17.3333 12.3333C17.3333 15.2917 14.9167 17.6667 12 17.6667C9.04167 17.6667 6.66667 15.2917 6.66667 12.3333Z"
  {fill}
/>
