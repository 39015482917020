<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M 22,4.6666754 H 1.999999 v 2 H 22 Z m 0,5.9999826 H 1.999999 v 8.666665 H 22 Z M 1.999999,2.6666765 H 22 24 v 1.9999989 14.6666476 2 H 22 1.999999 0 v -2 V 4.6666754 2.6666765 Z M 8.000003,15.333324 v 2 H 3.999998 v -2 z m 7.999998,0 v 2 H 9.333336 v -2 z"
  {fill}
/>
