<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M14 18L20 12M20 12L14 6M20 12L4 12"
  stroke="currentcolor"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
  {fill}
/>
