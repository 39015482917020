<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="m 18,0 v 9 h 3 v 1.5 H 13.5 V 9 h 3 V 1.5 h -3 V 0 Z M 7.4999998,13.5 v 9 H 10.5 V 24 H 2.9999998 v -1.5 h 3 V 15 h -3 v -1.5 z m -4.5,-13.5 H 12 V 10.5 H 2.9999998 Z m 1.5,1.5 V 9 H 10.5 V 1.5 Z M 21,13.5 V 24 H 12 V 13.5 Z m -7.5,9 h 6 V 15 h -6 z"
  {fill}
/>
