<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  {fill}
  d="M 17.489086,7.1812421 17.076607,6.9562418 12.014041,3.8812381 6.914066,6.9562418 6.5015615,7.1812421 H 6.0140649 1.8140599 V 19.181248 h 7.7999773 v -3.000003 c 0,-1.312482 1.0500018,-2.400003 2.4000038,-2.400003 1.312561,0 2.400002,1.087521 2.400002,2.400003 v 3.000003 h 7.800009 V 7.1812421 h -4.200004 z m 4.724966,-1.8000022 h 1.800002 V 7.1812421 19.181248 20.98125 H 22.214052 1.8140599 0.0140545 V 19.181248 7.1812421 5.3812399 h 1.8000054 4.200005 L 12.014041,1.7812372 18.014048,5.3812399 Z M 6.0140649,8.9812442 V 12.58124 H 3.614062 V 8.9812442 Z m 11.9999831,0 h 2.400003 V 12.58124 H 18.014048 Z M 6.0140649,13.781242 v 3.600004 H 3.614062 v -3.600004 z m 11.9999831,0 h 2.400003 v 3.600004 H 18.014048 Z M 9.0140365,8.9812442 c 0,-1.0500012 0.5625607,-2.0250024 1.5000025,-2.5874991 0.900001,-0.5250006 2.062562,-0.5250006 3.000003,0 0.900001,0.5624967 1.500002,1.5374979 1.500002,2.5874991 0,1.0875128 -0.600001,2.0624748 -1.500002,2.6250348 -0.937441,0.524961 -2.100002,0.524961 -3.000003,0 C 9.5765972,11.043719 9.0140365,10.068757 9.0140365,8.9812442 Z M 12.614041,7.1812421 H 11.41404 V 7.7812428 8.9812442 9.581237 h 0.600001 0.900001 0.6 V 8.3812435 h -0.6 -0.300001 V 7.7812428 Z"
/>
