<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="m 0.100003,15.000001 c 0,-2.324977 1.500001,-4.350001 3.600002,-5.062465 V 9.6 c 0,-3.299986 2.662507,-5.9999864 5.999996,-5.9999864 1.762512,0 3.375024,0.7875024 4.5,2.0625024 0.45,-0.15 0.975025,-0.262503 1.500001,-0.262503 2.625024,0 4.8,2.175001 4.8,4.799987 v 0.75 c 2.062513,0.562512 3.600001,2.437536 3.600001,4.650001 v 3 1.8 h -1.8 H 19.300002 5.5 1.900004 0.100003 v -1.8 z m 22.2,0.6 c 0,-1.387489 -0.974976,-2.550001 -2.250001,-2.887489 l -1.35,-0.337488 V 10.95 10.2 c 0,-1.65 -1.35,-2.999986 -3,-2.999986 -0.337488,0 -0.674976,0.075 -0.937488,0.15 l -1.125025,0.375 -0.787488,-0.9 C 12.062513,5.962516 10.937489,5.400013 9.700001,5.400013 7.375024,5.400013 5.5,7.312516 5.5,9.6 v 0.337536 1.274976 l -1.199995,0.412512 c -1.425001,0.487488 -2.400001,1.837488 -2.400001,3.374977 v 3.6 H 5.5 19.300002 22.300003 Z M 6.7,9 h 0.6 2.400001 0.6 v 0.6 2.4 0.6 h -0.6 H 7.3 6.7 V 12 9.6 Z m 1.2,1.2 v 1.2 H 9.100001 V 10.2 Z M 10.900001,9 h 0.6 0.6 0.6 v 0.6 2.4 0.6 h -1.2 V 12 10.2 h -0.6 z m 3.6,0 h 2.400001 0.6 v 0.6 2.4 0.6 h -0.6 -2.400001 -0.6 V 12 9.6 9 Z m 0.600001,2.4 h 1.2 v -1.2 h -1.2 z M 7.3,13.8 h 0.6 0.600001 0.6 v 0.6 2.400001 0.6 H 7.9 v -0.6 -1.8 H 7.3 Z m 3.000001,0 h 0.6 2.4 0.6 v 0.6 2.400001 0.6 h -0.6 -2.4 -0.6 v -0.6 V 14.4 Z m 1.2,1.200001 v 1.2 h 1.2 v -1.2 z M 15.100002,13.8 h 0.6 0.6 v 0.6 2.400001 0.6 h -1.2 v -0.6 -1.8 H 14.500001 V 13.8 Z"
  {fill}
/>
