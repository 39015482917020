<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<g clip-path="url(#clip0_1988_11031)">
  <path
    d="M3.63125 9.8375V9.5C3.63125 6.2 6.29375 3.5 9.63125 3.5C11.3938 3.5 13.0063 4.2875 14.1313 5.5625C14.5813 5.4125 15.1063 5.3 15.6313 5.3C18.2563 5.3 20.4313 7.475 20.4313 10.1V10.85C22.4938 11.4125 24.0313 13.2875 24.0313 15.5V18.5V20.3H22.2313H19.2313H5.43125H1.83125H0.03125V18.5V14.9C0.03125 12.575 1.53125 10.55 3.63125 9.8375ZM22.2313 18.5V18.5375V15.5375C22.2313 14.1125 21.2563 12.95 19.9813 12.6125L18.6313 12.275V10.8875V10.1375C18.6313 8.45 17.2813 7.1375 15.6313 7.1375C15.2938 7.1375 14.9563 7.175 14.6938 7.25L13.5688 7.625L12.7813 6.725C11.9938 5.8625 10.8688 5.3 9.63125 5.3C7.30625 5.3 5.43125 7.2125 5.43125 9.5V9.8375V11.1125L4.23125 11.525C2.80625 12.0125 1.83125 13.3625 1.83125 14.9V18.5H5.43125H19.2313H22.2313Z"
    {fill}
  />
</g>
<defs>
  <clipPath id="clip0_1988_11031">
    <rect width="24" height="24" fill="white" />
  </clipPath>
</defs>
