<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M 12,10.541667 20.708333,6.6666669 12,2.7916666 3.25,6.6666669 Z M 22.333333,5.9583332 24,6.6666669 l -1.666667,0.75 L 12,12 1.625,7.4166669 0,6.6666669 1.625,5.9583332 12,1.3333332 Z M 6,10.791667 3.25,12 12,15.875 20.708333,12 18,10.791667 19.625,10.083333 22.333333,11.291667 24,12 22.333333,12.75 12,17.333333 1.625,12.75 0,12 1.625,11.291667 4.333333,10.083333 Z m -1.666667,4.625 L 6,16.125 l -2.75,1.208333 8.75,3.875 8.708333,-3.875 L 18,16.125 19.625,15.416667 22.333333,16.625 24,17.333333 l -1.666667,0.75 L 12,22.666667 1.625,18.083333 0,17.333333 1.625,16.625 Z"
  {fill}
/>
