<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="m 5.757189,0 6.225252,8.9868343 h 6.740126 V 5.6167715 4.4934172 h 1.123354 2.995612 1.123354 V 6.7401258 H 22.841533 20.969275 V 8.9868343 10.48464 c 0,1.31058 -0.32766,2.62116 -0.842485,3.838097 -0.561678,1.170206 -1.357432,2.246709 -2.340352,3.136046 -0.98292,0.889337 -2.106274,1.591449 -3.370063,2.059483 -1.263788,0.468094 -2.62116,0.702111 -3.93174,0.702111 -1.3573712,0 -2.7147426,-0.234017 -3.9785312,-0.702111 C 5.2423212,19.050232 4.1189669,18.34812 3.1360289,17.458783 2.1530969,16.569446 1.3573846,15.492943 0.79570749,14.322737 0.28083857,13.1058 0,11.79522 0,10.48464 V 8.9868343 C 0.14041929,5.0550943 2.4339355,1.6850314 5.757189,0 Z M 3.7445155,19.471474 c 0.7957063,0 1.4978057,0.468035 1.9190606,1.123355 0.4212848,0.702111 0.4212848,1.591388 0,2.246708 -0.4212549,0.702112 -1.1233543,1.123355 -1.9190606,1.123355 -0.8425157,0 -1.5446151,-0.421243 -1.96587,-1.123355 -0.4212609,-0.65532 -0.4212609,-1.544597 0,-2.246708 0.4212549,-0.65532 1.1233543,-1.123355 1.96587,-1.123355 z m 11.2335365,2.246709 c 0,-0.795694 0.421243,-1.497806 1.123355,-1.919049 0.65532,-0.421242 1.544597,-0.421242 2.246708,0 0.65532,0.421243 1.123355,1.123355 1.123355,1.919049 0,0.842486 -0.468035,1.544597 -1.123355,1.96584 -0.702111,0.421303 -1.591388,0.421303 -2.246708,0 -0.702112,-0.421243 -1.123355,-1.123354 -1.123355,-1.96584 z M 2.6211612,12.731349 c 0.046804,0.234017 0.1404163,0.421243 0.2340292,0.65532 0.4212608,0.889277 1.0297414,1.731823 1.7786443,2.433874 0.7957123,0.65532 1.6850434,1.216997 2.6679634,1.591449 1.0297714,0.374451 2.0594829,0.561677 3.1828369,0.561677 1.076563,0 2.153126,-0.187226 3.136046,-0.561677 0.98292,-0.374452 1.919049,-0.936129 2.667951,-1.591449 0.748903,-0.702051 1.357372,-1.544597 1.778675,-2.433874 0.09358,-0.234077 0.187225,-0.421303 0.280808,-0.65532 0.140435,-0.468035 0.280869,-0.98292 0.32766,-1.497806 H 2.2467098 c 0.093613,0.514886 0.1872257,1.029771 0.3744514,1.497806 z"
  {fill}
/>
