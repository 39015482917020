<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="m 19.5,1.5 v 3 h 3 v -3 z M 18,0 h 1.5 3 1.5 v 1.5 3 1.5 h -1.5 -3 -1.5 v -1.5 -3 z m -4.59375,0.09375 0.5625,0.46875 2.25,1.875 L 16.921875,3 16.21875,3.609375 l -2.25,1.875 -0.5625,0.46875 -0.984375,-1.171875 0.5625,-0.46875 0.65625,-0.515625 H 1.5 V 11.25 H 23.25 24 V 12 21 21.75 H 23.25 19.5 V 22.5 24 H 18 15 13.5 v -1.5 -3 -1.5 h 1.5 3 1.5 v 1.5 0.75 h 3 v -7.5 H 0.75 0 V 12 3 2.25 H 0.75 13.640625 L 12.984375,1.734375 12.421875,1.21875 Z M 6,19.5 v 0.75 h 3.140625 l -0.65625,-0.515625 -0.5625,-0.515625 0.984375,-1.125 0.5625,0.46875 2.25,1.875 0.703125,0.5625 -0.703125,0.609375 -2.25,1.875 -0.5625,0.46875 L 7.921875,22.78125 8.484375,22.3125 9.140625,21.75 H 6 V 22.5 24 H 4.5 1.5 0 v -1.5 -3 -1.5 h 1.5 3 1.5 z m -1.5,0 h -3 v 3 h 3 z m 10.5,0 v 3 h 3 v -3 z"
  {fill}
/>
