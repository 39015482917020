<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="m 6.9843634,12.679702 h 8.9999986 0.984359 v 0.98436 1.921859 l 3.32814,-2.109359 0.84378,-0.51564 0.562499,0.84378 1.64058,2.671859 0.51564,0.79686 -0.79686,0.5625 -4.874999,3.32814 -0.23436,0.1875 H 17.625001 0.9843766 0 v -2.01564 H 0.9843766 17.343721 l 3.79692,-2.625 -0.60942,-0.98436 -3.375,2.10936 -0.23436,0.14064 h -0.28122 -1.312499 -2.01564 -0.98436 -1.687499 -0.9844201 v -1.96872 h 0.9844201 1.687499 0.98436 1.6875 V 14.648422 H 7.2656433 l -3.7500172,2.531279 -0.234378,0.14058 H 2.9999982 0.9843766 0 v -1.96872 h 0.9843766 1.6874997 l 3.7499872,-2.531279 0.23436,-0.14058 z M 16.968721,11.320283 H 7.9687232 6.9843634 V 10.335923 8.4140633 L 3.6562481,10.523423 2.8124982,11.039063 2.2499984,10.195283 0.60937668,7.5234355 0.09374998,6.7265637 0.89062662,6.1640638 5.7656436,2.8359395 l 0.23436,-0.1875 H 6.3281435 22.96872 24 V 4.6640641 H 22.96872 6.6093635 l -3.7968653,2.6249995 0.6093779,0.9843718 3.3749873,-2.1093716 0.23436,-0.1875 h 0.2812799 1.3124998 2.0156399 0.984359 1.6875 0.98436 v 2.0156276 h -0.98436 -1.6875 -0.984359 -1.64064 v 1.3593718 h 7.687498 l 3.75,-2.5312495 0.23436,-0.140622 H 21.000001 22.96872 24 v 1.9687316 h -1.03128 -1.687499 l -3.75,2.5312797 -0.23436,0.14058 z"
  {fill}
/>
