<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M4.02363 6.16797C3.98198 7.97266 4.31519 10.5566 5.39815 13.0996C6.60606 15.7656 8.60536 18.3496 12.0208 19.8672C15.3946 18.3496 17.3939 15.7656 18.6019 13.0996C19.7265 10.5566 20.0597 7.97266 20.018 6.16797L12.0208 3.13281L4.02363 6.16797ZM21.9757 5.63477C22.1006 7.68555 21.7674 10.8437 20.4345 13.8789C19.06 16.9961 16.6442 20.0312 12.5207 21.7949L12.0208 22L11.4793 21.7949C7.35579 20.0312 4.93997 16.9961 3.56546 13.8789C2.23259 10.8437 1.89937 7.68555 2.02433 5.63477L2.06598 4.81445L2.85737 4.48633L11.521 1.20508L12.0208 1L12.479 1.20508L21.1426 4.48633L21.934 4.81445L21.9757 5.63477ZM16.7275 9.57227L11.396 14.8223L10.688 15.5195L9.97988 14.8223L7.31414 12.1973L6.60606 11.5L8.02223 10.1465L8.73031 10.8437L10.688 12.7715L15.3113 8.21875L16.0194 7.52148L17.4356 8.875L16.7275 9.57227Z"
  {fill}
/>
