<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M20.1211 8.49609C20.5039 8.63281 20.75 8.90625 20.75 9.34375C20.75 9.75391 20.5039 10.082 20.1211 10.2188L12.3008 13.0078C12.1914 13.0625 12.082 13.0625 11.9727 13.0625C11.8906 13.0625 11.7812 13.0625 11.6719 13.0078L5.4375 10.7656V13.5L6.28516 17.3555C6.3125 17.4922 6.28516 17.6289 6.20312 17.7109C6.12109 17.8203 5.98438 17.875 5.84766 17.875H4.125C3.98828 17.875 3.85156 17.8203 3.76953 17.7383C3.6875 17.6289 3.66016 17.4922 3.6875 17.3555L4.5625 13.5547V10.4648L3.85156 10.1914C3.46875 10.0547 3.25 9.69922 3.25 9.34375C3.25 8.96094 3.46875 8.63281 3.85156 8.49609L11.6719 5.67969C11.8633 5.625 12.1094 5.625 12.3008 5.67969L20.1211 8.49609ZM4.67188 17H5.32812L5 15.5508L4.67188 17ZM11.9727 12.1875L19.875 9.34375L12 6.52734L4.125 9.34375L5.41016 9.83594L11.8633 7.83984C12.082 7.78516 12.3281 7.89453 12.4102 8.14062C12.4648 8.35938 12.3555 8.60547 12.1094 8.6875L6.77734 10.3281L11.9727 12.1875ZM16.4297 12.6523C16.6758 12.5977 16.8945 12.7891 16.9219 13.0078L17.2227 15.3867C17.3047 16.0156 16.9492 16.6172 16.3203 16.918C15.0078 17.5195 13.5312 17.8477 12.0547 17.875H11.9453C10.4414 17.8477 8.96484 17.5195 7.65234 16.918C7.02344 16.6172 6.66797 16.0156 6.75 15.3867L7.05078 13.0078C7.07812 12.7891 7.29688 12.5977 7.54297 12.6523C7.78906 12.6797 7.95312 12.8984 7.92578 13.1445L7.625 15.4961C7.57031 15.7422 7.73438 15.9883 8.00781 16.125C9.21094 16.6719 10.5781 16.9727 11.9453 17H12.0273C13.3945 16.9727 14.7617 16.6719 15.9648 16.125C16.2383 15.9883 16.4023 15.7422 16.3477 15.4961L16.0469 13.1445C16.0195 12.8984 16.1836 12.6797 16.4297 12.6523Z"
  {fill}
/>
