<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M 19.799995,5.3999951 H 4.2000082 V 14.399998 H 2.4000061 V 5.3999951 3.5999945 H 4.2000082 19.799995 21.599997 V 5.3999951 14.399998 H 19.799995 Z M 3.1125029,18.600002 H 20.849996 l 1.200002,-1.200001 H 1.9125015 Z M 21.599997,20.400005 H 2.4000061 L 0,18.000002 v -0.6 -1.800003 H 1.8000054 22.199998 24 v 1.800003 0.6 z"
  {fill}
/>
