<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M 10.656368,21.637066 H 21.776061 V 7.5521209 h -5.18919 V 2.3629307 H 8.4324294 V 5.6525096 L 8.3397949,5.5135104 7.2741428,5.930496 6.2084907,6.3938343 V 2.3629307 0.138992 H 8.4324294 18.069497 L 24,6.0694951 V 21.637066 23.861004 H 21.776061 10.656368 Z M 4.5405426,7.3204754 4.7721999,7.4131099 6.2084907,8.0154118 7.598482,7.4131099 l 0.2316455,-0.092634 0.1853282,0.2316455 0.9266411,1.2046038 1.4826262,0.1853875 0.277962,0.046317 0.04638,0.2779627 0.185328,1.4826256 1.204604,0.926641 0.231645,0.185328 -0.138951,0.231646 -0.555985,1.389991 0.602302,1.436309 0.09263,0.231645 -0.231645,0.185328 -1.204604,0.926641 -0.185328,1.482626 -0.04638,0.277963 -0.277962,0.04638 -1.2509808,0.185329 v 5.606163 L 6.2084907,22.007722 3.2432451,23.861004 V 18.254841 L 1.9459475,18.069512 1.6679552,18.023136 1.6216202,17.745173 1.4362919,16.262547 0.2316585,15.335906 0,15.150578 0.13899439,14.918933 0.69497905,13.482624 0.13899439,12.092633 0,11.860987 0.2316585,11.675659 1.4362919,10.749018 1.6216202,9.2663921 1.6679552,8.9884294 1.9459475,8.9421122 3.4285733,8.7567247 4.3552144,7.5521209 Z m 4.6331996,6.1621486 c 0,-1.019335 -0.6023019,-1.992293 -1.4826257,-2.548278 -0.9266411,-0.509608 -2.0849277,-0.509608 -2.9652457,0 -0.9266411,0.555985 -1.4826257,1.528943 -1.4826257,2.548278 0,1.065652 0.5559846,2.03861 1.4826257,2.594595 0.880318,0.509668 2.0386046,0.509668 2.9652457,0 0.8803238,-0.555985 1.4826257,-1.528943 1.4826257,-2.594595 z"
  {fill}
/>
