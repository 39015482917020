<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="m 20.25,10.5 c 0,-4.546872 -3.70314,-8.25 -8.25,-8.25 -4.59375,0 -8.25,3.703128 -8.25,8.25 0,3.32814 1.96875,6.23436 4.78128,7.5 H 4.640628 C 2.671872,16.125 1.5,13.45314 1.5,10.5 1.5,4.734372 6.1875,0 12,0 c 5.76564,0 10.5,4.734372 10.45314,10.5 0,2.95314 -1.17186,5.625 -3.14064,7.5 H 15.42186 C 18.28128,16.73436 20.25,13.82814 20.25,10.5 Z M 9.79686,5.953128 12,6.75 9.79686,7.59372 9,9.75 8.15628,7.59372 6,6.75 8.15628,5.953128 9,3.75 Z M 15,6 16.17186,9.32814 19.5,10.5 16.17186,11.71872 15,15 13.78128,11.71872 10.5,10.5 13.78128,9.32814 Z m -13.5,18 3,-4.5 h 15 l 3,4.5 z"
  {fill}
/>
