<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M7 5V6.7375V17.2625V19L8.5 18.125L17.5125 12.8687L19 12L17.5125 11.1312L8.5 5.875L7 5ZM8.5 7.6125L16.0219 12L8.5 16.3875V7.6125Z"
  {fill}
/>
