<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="m 11.920008,0.053573 c -5.0107412,0 -8.9912335,4.0741477 -8.9912335,8.9912335 C 2.9287745,12.416519 4.0058443,13.446781 10,23 c 0.889759,1.404764 2.903423,1.404764 3.793182,0 5.994089,-9.553219 7.11806,-10.583481 7.11806,-13.9551935 0,-4.9639068 -4.074161,-8.9912335 -8.991234,-8.9912335 z m 0,21.728814 C 5.9258523,12.229195 5.1765828,11.620415 5.1765828,9.0448065 c 0,-3.6995129 2.9970779,-6.7434251 6.7434252,-6.7434251 3.699513,0 6.743425,3.0439122 6.743425,6.7434251 0,2.5756085 -0.749269,3.0907335 -6.743425,12.7375805 z M 8.1736607,9.0448065 c 0,2.1073185 1.6390286,3.7463475 3.7463473,3.7463475 2.060498,0 3.746347,-1.639029 3.746347,-3.7463475 0,-2.0604976 -1.685849,-3.7463473 -3.746347,-3.7463473 -2.1073187,0 -3.7463473,1.6858497 -3.7463473,3.7463473 z"
  {fill}
/>
