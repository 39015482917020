<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M 2.0000062,5.9999954 V 18.000002 H 13.999987 V 5.9999954 Z M 0,3.9999945 H 2.0000062 13.999987 15.999989 V 5.9999954 7.999998 16 18.000002 20.000005 H 13.999987 2.0000062 0 V 18.000002 5.9999954 Z m 17.333324,10.4583135 4.666673,2.333338 V 7.249997 L 17.333324,9.5833335 V 7.3333305 L 21.999997,4.999994 24,3.9999945 v 2.2500012 11.5416503 2.208359 l -2.000003,-1.000002 -4.666673,-2.333336 z"
  {fill}
/>
