<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  transform="translate(4 4)"
  d="M10.5 5.5H1.5V14.5H10.5V5.5ZM10.5 4V1.5H1.5V4H10.5ZM12 4V4.75V5.5V14.5V16H10.5H1.5H0V14.5V5.5V4.75V4V1.5V0H1.5H10.5H12V1.5V4ZM2.5 6.5H4V8H2.5V6.5ZM4 9.25V10.75H2.5V9.25H4ZM2.5 12H6.75V13.5H2.5V12ZM6.75 6.5V8H5.25V6.5H6.75ZM5.25 9.25H6.75V10.75H5.25V9.25ZM9.5 6.5V8H8V6.5H9.5ZM8 9.25H9.5V10.75H8V9.25ZM9.5 12V13.5H8V12H9.5Z"
  {fill}
/>
