<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M 7.5000001,0 V 0.75 3 H 16.5 V 0.75 0 H 18 V 0.75 3 h 3 1.5 V 4.5 7.5 9 22.5 24 H 21 3.0000001 h -1.5 V 22.5 9 7.5 4.5 3 h 1.5 3 V 0.75 0 Z M 21,9 h -4.875 v 3.375 H 21 Z m 0,4.875 h -4.875 v 3.75 H 21 Z m 0,5.25 H 16.125 V 22.5 H 21 Z m -6.375,-1.5 v -3.75 H 9.3750001 v 3.75 z m -5.2499999,1.5 V 22.5 H 14.625 v -3.375 z m -1.5,-1.5 v -3.75 h -4.875 v 3.75 z m -4.875,1.5 V 22.5 h 4.875 v -3.375 z m 0,-6.75 h 4.875 V 9 h -4.875 z m 6.375,0 H 14.625 V 9 H 9.3750001 Z M 21,4.5 H 3.0000001 v 3 H 21 Z"
  {fill}
/>
