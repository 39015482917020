<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M19.6992 7.60938L10.1367 17.1719C10.0312 17.2773 9.89062 17.3125 9.75 17.3125C9.57422 17.3125 9.43359 17.2773 9.32812 17.1719L4.26562 12.1094C4.05469 11.8984 4.05469 11.5117 4.26562 11.3008C4.47656 11.0898 4.86328 11.0898 5.07422 11.3008L9.75 15.9766L18.8906 6.80078C19.1016 6.58984 19.4883 6.58984 19.6992 6.80078C19.9102 7.01172 19.9102 7.39844 19.6992 7.60938Z"
  {fill}
/>
