<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M4.25 2.25V5.25H7.25V2.25H4.25ZM2 0H9.5V7.5H6.875V12C7.8125 11.2969 8.98438 10.875 10.25 10.875H14.75C16.5781 10.875 18.125 9.375 18.125 7.5H15.5V0H23V7.5H20.375C20.375 10.6406 17.8438 13.125 14.75 13.125H10.25C8.375 13.125 6.875 14.6719 6.875 16.5H9.5V24H2V16.5H4.625V7.5H2V0ZM7.25 18.75H4.25V21.75H7.25V18.75ZM20.75 2.25H17.75V5.25H20.75V2.25Z"
  {fill}
/>
