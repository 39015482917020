<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M0.595899 3.34165L-0.0791016 2.60415L1.3959 1.25415L2.0709 1.99165L9.40423 9.99165L10.0251 10.6666L9.40423 11.3416L2.0709 19.3416L1.3959 20.0791L-0.0791016 18.7291L0.595899 17.9916L7.3084 10.6666L0.595899 3.34165ZM10.3334 18H23.0001H24.0001V20H23.0001H10.3334H9.3334V18H10.3334Z"
  {fill}
/>
