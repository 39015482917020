<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M8.5 7.5V16.5H10V7.5H8.5ZM7 6H8.5H10H11.5V7.5V16.5V18H10H8.5H7V16.5V7.5V6ZM14 7.5V16.5H15.5V7.5H14ZM12.5 6H14H15.5H17V7.5V16.5V18H15.5H14H12.5V16.5V7.5V6Z"
  {fill}
/>
