<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<g clip-path="url(#clip0_1786_8348)">
  <path
    d="M1.5 4.125C1.5 1.84688 3.34688 0 5.625 0H21.375H22.5V1.125V17.625V18.75H21.375H21V21.75H21.375H22.5V24H21.375H5.25C3.17812 24 1.5 22.3219 1.5 20.25C1.5 20.1234 1.50469 19.9969 1.51875 19.875H1.5V4.125ZM3.75 20.25C3.75 21.0797 4.42031 21.75 5.25 21.75H18.75V18.75H5.25C4.42031 18.75 3.75 19.4203 3.75 20.25ZM3.75 16.8141C4.20937 16.6125 4.71563 16.5 5.25 16.5H20.25V2.25H5.625C4.58906 2.25 3.75 3.08906 3.75 4.125V16.8141ZM9 5.25L9.75 3.75L10.5 5.25L12 6L10.5 6.75L9.75 8.25L9 6.75L7.5 6L9 5.25ZM13.875 10.125L15 7.5L16.125 10.125L18.75 11.25L16.125 12.375L15 15L13.875 12.375L11.25 11.25L13.875 10.125Z"
    {fill}
  />
</g>
<defs>
  <clipPath id="clip0_1786_8348">
    <rect width="21" height="24" fill="white" transform="translate(1.5)" />
  </clipPath>
</defs>
