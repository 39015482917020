<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M13.25 13.25V22H10.75V13.25H2V10.75H10.75V2H13.25V10.75H22V13.25H13.25Z"
  {fill}
  fill-rule="evenodd"
  clip-rule="evenodd"
/>
