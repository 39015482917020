<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M18.125 4.75C19.082 4.75 19.875 5.54297 19.875 6.5V14.375C19.875 15.3594 19.082 16.125 18.125 16.125H13.0938L13.6406 17.875H15.9375C16.1562 17.875 16.375 18.0938 16.375 18.3125C16.375 18.5586 16.1562 18.75 15.9375 18.75H8.0625C7.81641 18.75 7.625 18.5586 7.625 18.3125C7.625 18.0938 7.81641 17.875 8.0625 17.875H10.332L10.8789 16.125H5.875C4.89062 16.125 4.125 15.3594 4.125 14.375V6.5C4.125 5.54297 4.89062 4.75 5.875 4.75H18.125ZM13.6406 17.875L13.0938 16.125H10.8789L10.332 17.875H13.6406ZM19 14.375V6.5C19 6.03516 18.5898 5.625 18.125 5.625H5.875C5.38281 5.625 5 6.03516 5 6.5V14.375C5 14.8672 5.38281 15.25 5.875 15.25H18.125C18.5898 15.25 19 14.8672 19 14.375Z"
  {fill}
/>
