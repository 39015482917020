<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  {fill}
  d="M 4.5000108,1.4999905 H 5.6250124 22.874998 24 V 2.6249902 21.375008 22.500009 H 22.874998 6.7500141 5.6250124 3.3750091 2.2500074 1.1250057 0 V 21.375008 5.2499941 4.1249924 H 2.2500074 V 5.2499941 20.250006 H 3.3750091 4.5000108 V 2.6249902 Z M 6.7500141,20.250006 H 21.749997 V 3.7499919 H 6.7500141 Z M 8.2500164,5.2499941 H 14.999986 V 11.250003 H 8.2500164 Z m 9.3749736,0 h 1.500003 1.125001 V 7.4999975 H 19.124993 17.62499 16.499989 V 5.2499941 Z m 0,3.7500056 h 1.500003 1.125001 V 11.250003 H 19.124993 17.62499 16.499989 V 8.9999997 Z M 9.3749781,12.749995 h 9.7500149 1.125001 v 2.250003 H 19.124993 9.3749781 8.2500164 v -2.250003 z m 0,3.750005 h 9.7500149 1.125001 v 2.250004 H 19.124993 9.3749781 8.2500164 V 16.5 Z"
/>
