<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M7.95168 2.25L7.32887 9.23438C7.08932 11.9531 9.24523 14.25 12.0239 14.25C14.7548 14.25 16.9107 11.9531 16.6711 9.23438L16.0483 2.25H14.7548L15.0901 3.75L12.0239 5.625L13.557 10.5L8.95777 5.625L12.4072 2.25H7.95168ZM10.8741 0H14.3236H18.1563L18.9708 9.04688C19.3061 12.6563 16.719 15.8438 13.1738 16.4063V21.75H17.3898V24H12.0239H6.65814V21.75H10.8741V16.4063C7.28096 15.8438 4.69387 12.7031 5.02924 9.04688L5.8916 0H10.8741Z"
  {fill}
/>
