<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M 4.875,0 H 8.625 9.75 V 1.125 6.75 H 12.375 13.5 V 7.875 8.25 h 2.625 1.125 V 9 H 19.875 21 V 10.125 12 16.125 16.17186 C 20.95314,20.48436 17.4375,24 13.125,24 h -2.25 C 6.515628,24 3,20.48436 3,16.125 V 15 12.375 11.25 H 3.75 V 1.125 0 Z M 18.75,12 v -0.75 h -1.5 v 4.5 h 1.5 z m -0.32814,6 H 16.125 15 V 17.25 H 13.5 12.375 11.25 8.625 7.5 V 15 H 8.625 11.25 V 13.5 H 8.625 5.25 v 1.5 1.125 c 0,3.14064 2.484372,5.625 5.625,5.625 h 2.25 c 2.4375,0 4.5,-1.54686 5.29686,-3.75 z M 7.5,11.25 V 7.875 6.75 2.25 H 6 v 9 z m 3.75,0 V 9.375 9 h -1.5 v 2.25 z M 13.5,10.5 V 12.375 15 H 15 v -4.5 z"
  {fill}
/>
