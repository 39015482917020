<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M 20,5.3333334 C 20,6.8333334 21.166667,8 22.666667,8 V 5.3333334 Z m -1.333333,0 H 5.3333334 c 0,2.2083333 -1.7916667,3.9999996 -4.0000001,3.9999996 v 5.333334 c 2.2083334,0 4.0000001,1.791666 4.0000001,4 H 18.666667 c 0,-2.208334 1.791666,-4 4,-4 V 9.333333 c -2.208334,0 -4,-1.7916663 -4,-3.9999996 z M 1.3333333,18.666667 H 4 C 4,17.208333 2.7916667,16 1.3333333,16 Z M 22.666667,16 C 21.166667,16 20,17.208333 20,18.666667 h 2.666667 z M 1.3333333,5.3333334 V 8 C 2.7916667,8 4,6.8333334 4,5.3333334 Z M 0,4 H 24 V 20 H 0 Z m 14.666667,8 c 0,-0.916667 -0.541667,-1.791667 -1.333334,-2.291667 C 12.5,9.25 11.458333,9.25 10.666667,9.708333 c -0.8333336,0.5 -1.3333336,1.375 -1.3333336,2.291667 0,0.958333 0.5,1.833333 1.3333336,2.333333 0.791666,0.458334 1.833333,0.458334 2.666666,0 0.791667,-0.5 1.333334,-1.375 1.333334,-2.333333 z M 8,12 c 0,-1.416667 0.75,-2.708333 2,-3.458333 1.208333,-0.7083336 2.75,-0.7083336 4,0 1.208333,0.75 2,2.041666 2,3.458333 0,1.458333 -0.791667,2.75 -2,3.5 -1.25,0.708333 -2.791667,0.708333 -4,0 C 8.75,14.75 8,13.458333 8,12 Z"
  {fill}
/>
