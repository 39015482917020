<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M1 2.5H23V4.9375H1V2.5ZM1 10.625H23V13.0625H1V10.625ZM23 18.75V21.1875H1V18.75H23Z"
  {fill}
/>
