<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M21.75 11.9766C21.75 8.50781 19.875 5.32031 16.875 3.53906C13.8281 1.80469 10.125 1.80469 7.125 3.53906C4.07813 5.32031 2.25 8.50781 2.25 11.9766C2.25 15.4922 4.07813 18.6797 7.125 20.4609C10.125 22.1953 13.8281 22.1953 16.875 20.4609C19.875 18.6797 21.75 15.4922 21.75 11.9766ZM0 11.9766C0 7.71094 2.25 3.77344 6 1.61719C9.70313 -0.539063 14.25 -0.539063 18 1.61719C21.7031 3.77344 24 7.71094 24 11.9766C24 16.2891 21.7031 20.2266 18 22.3828C14.25 24.5391 9.70313 24.5391 6 22.3828C2.25 20.2266 0 16.2891 0 11.9766ZM12 8.97656C13.0313 8.97656 14.0156 9.58594 14.5781 10.4766C15.0938 11.4141 15.0938 12.5859 14.5781 13.4766C14.0156 14.4141 13.0313 14.9766 12 14.9766C10.9219 14.9766 9.9375 14.4141 9.375 13.4766C8.85938 12.5859 8.85938 11.4141 9.375 10.4766C9.9375 9.58594 10.9219 8.97656 12 8.97656Z"
  {fill}
/>
