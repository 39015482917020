<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M 13.800002,4.2000085 H 4.199998 v 1.799999 h 4.800004 0.6 v 1.199986 h -0.6 H 0.599999 0 v -1.199986 h 0.599999 1.8 v -1.799999 -1.8 h 1.799999 9.600004 1.799999 v 1.8 1.799999 h 2.1 0.337488 l 0.262512,0.262503 4.237487,4.1999935 L 22.8,10.725016 v 0.374976 5.099999 h 0.3 0.9 v 1.8 h -0.9 -1.5 c 0,1.987536 -1.612511,3.600001 -3.599999,3.600001 -1.987488,0 -3.599999,-1.612464 -3.599999,-3.600001 h -0.3 -0.3 -1.8 -2.4 c 0,1.987536 -1.612511,3.600001 -3.599999,3.600001 -1.987502,0 -3.600004,-1.612464 -3.600004,-3.600001 v -1.8 -2.999998 h 1.799999 v 1.687536 c 0.524981,-0.3 1.124981,-0.487536 1.800005,-0.487536 1.312512,0 2.474975,0.75 3.112511,1.799999 h 2.887488 1.8 z m 1.799999,3.599985 v 3.5999995 h 5.324975 L 17.324977,7.7999935 Z M 7.800003,17.999991 c 0,-0.637488 -0.375024,-1.2 -0.9,-1.537488 -0.562512,-0.337488 -1.275024,-0.337488 -1.8,0 -0.562502,0.337488 -0.900005,0.9 -0.900005,1.537488 0,0.675024 0.337503,1.237536 0.900005,1.575024 0.524976,0.337488 1.237488,0.337488 1.8,0 0.524976,-0.337488 0.9,-0.9 0.9,-1.575024 z m 10.199998,1.8 c 0.637488,0 1.2,-0.337488 1.537488,-0.899999 0.337488,-0.524976 0.337488,-1.237488 0,-1.8 -0.337488,-0.524976 -0.9,-0.9 -1.537488,-0.9 -0.675024,0 -1.237488,0.375024 -1.575024,0.9 -0.337488,0.562512 -0.337488,1.275023 0,1.8 0.337536,0.562512 0.9,0.899999 1.575024,0.899999 z M 1.799999,8.3999935 h 8.400003 0.6 v 1.199999 h -0.6 -8.400003 -0.6 v -1.199999 z m -1.2,2.3999995 h 8.400003 0.6 v 1.2 h -0.6 H 0.599999 0 v -1.2 z"
  {fill}
/>
