<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M10 18L4 12M4 12L10 6M4 12L20 12"
  stroke="currentcolor"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
  {fill}
/>
