<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M 7.5,23.999999 V 21.749998 21.328137 19.499996 H 5.25 2.25 0 V 17.249994 2.2500015 0 H 2.25 21.75 24 v 2.2500015 14.9999925 2.250002 h -2.25 -7.5 l -4.5,3.000002 z m 5.48436,-6.328084 0.5625,-0.421921 H 14.25 21.75 V 2.2500015 H 2.25 V 17.249994 H 7.5 9.75 v 2.250002 0.32814 z M 9,8.2499875 c -0.84372,0 -1.5,-0.65622 -1.5,-1.500001 0,-0.79686 0.65628,-1.499983 1.5,-1.499983 0.79686,0 1.5,0.703123 1.5,1.499983 0,0.843781 -0.70314,1.500001 -1.5,1.500001 z m 6,0 c -0.84372,0 -1.5,-0.65622 -1.5,-1.500001 0,-0.79686 0.65628,-1.499983 1.5,-1.499983 0.79686,0 1.5,0.703123 1.5,1.499983 0,0.843781 -0.70314,1.500001 -1.5,1.500001 z m -7.125,2.2031415 0.75,0.796861 c 0.79686,0.9375 2.01564,1.500002 3.32814,1.500002 1.35936,0 2.53122,-0.562502 3.375,-1.500002 l 0.75,-0.796861 1.6875,1.500002 -0.75,0.796861 c -1.26564,1.406281 -3.04692,2.250001 -5.0625,2.250001 -1.96878,0 -3.79686,-0.84372 -5.01564,-2.250001 l -0.75,-0.796861 z"
  {fill}
/>
