<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M22.875 12.2812C22.875 18.9375 18.3281 23.625 11.625 23.625C5.15625 23.625 0 18.4688 0 12C0 5.57812 5.15625 0.375 11.625 0.375C14.7188 0.375 17.3906 1.54688 19.4062 3.42188L16.2188 6.46875C12.0938 2.48438 4.40625 5.48438 4.40625 12C4.40625 16.0781 7.64062 19.3594 11.625 19.3594C16.2188 19.3594 17.9531 16.0781 18.1875 14.3438H11.625V10.3594H22.6875C22.7812 10.9688 22.875 11.5312 22.875 12.2812Z"
  {fill}
/>
