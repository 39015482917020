<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M18.8828 14.293C18.7949 14.4102 18.6777 14.4688 18.5312 14.4688C18.4434 14.4688 18.3262 14.4395 18.2383 14.3516L12.4668 9.04883L6.66602 14.3516C6.49023 14.5273 6.19727 14.5273 6.02148 14.3223C5.8457 14.1465 5.8457 13.8535 6.05078 13.6777L12.1445 8.05273C12.3203 7.87695 12.584 7.87695 12.7598 8.05273L18.8535 13.6777C19.0586 13.8242 19.0586 14.1172 18.8828 14.293Z"
  {fill}
/>
