<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<g clip-path="url(#clip0_1786_9599)">
  <g clip-path="url(#clip1_1786_9599)">
    <path
      d="M9.27656 11.9016C10.7859 6.58127 13.35 4.2094 15.6984 3.14065C17.7516 2.20315 19.9219 2.13752 21.6422 2.36252C21.8672 4.07815 21.8016 6.24846 20.8641 8.30627C19.7953 10.65 17.4234 13.2188 12.1031 14.7281C11.8031 14.1235 11.4094 13.5703 10.9219 13.0875C10.4344 12.6047 9.88125 12.2063 9.28125 11.9063L9.27656 11.9016ZM2.08125 13.486C2.1375 13.4953 2.19375 13.5 2.25 13.5H6.50156C7.56094 13.5 8.57812 13.9219 9.32812 14.6719C10.0781 15.4219 10.5 16.4391 10.5 17.4985V21.375C10.5 21.3797 10.5 21.3797 10.5 21.3844V24L18 20.25V14.6297C24.7266 10.4531 24.3938 3.7594 23.5641 0.435959C20.2406 -0.393728 13.5469 -0.726541 9.37031 6.00002H6.35156C6.34688 6.00002 6.34219 6.00002 6.3375 6.00002H3.75L0 13.4813L2.08125 13.486ZM0.0234375 23.9766C0.0234375 23.9766 5.48438 24.3516 7.80469 22.0313C9.41719 20.4188 9.41719 17.8078 7.80469 16.1953C6.19219 14.5828 3.58125 14.5828 1.96875 16.1953C-0.351562 18.5156 0.0234375 23.9766 0.0234375 23.9766ZM3.02812 20.9719C3.02812 20.9719 2.90156 19.1578 3.675 18.3844C4.20937 17.85 5.08125 17.85 5.61563 18.3844C6.15 18.9188 6.15 19.7906 5.61563 20.325C4.84219 21.0985 3.02812 20.9719 3.02812 20.9719ZM19.125 6.75002C19.125 6.25274 18.9275 5.77583 18.5758 5.4242C18.2242 5.07257 17.7473 4.87502 17.25 4.87502C16.7527 4.87502 16.2758 5.07257 15.9242 5.4242C15.5725 5.77583 15.375 6.25274 15.375 6.75002C15.375 7.2473 15.5725 7.72422 15.9242 8.07585C16.2758 8.42748 16.7527 8.62502 17.25 8.62502C17.7473 8.62502 18.2242 8.42748 18.5758 8.07585C18.9275 7.72422 19.125 7.2473 19.125 6.75002Z"
      {fill}
    />
  </g>
</g>
<defs>
  <clipPath id="clip0_1786_9599">
    <rect width="24" height="24" fill="white" />
  </clipPath>
</defs>
