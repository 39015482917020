<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M 6.9319488,6.9738203 C 6.3036629,6.3455451 5.9685842,5.5078484 5.9685842,4.6282696 V 4.4607303 c 0,-1.759163 1.3821861,-3.1832473 3.1413759,-3.1832473 0.9633109,0 1.8848039,0.4607332 2.4711649,1.2146575 L 12,2.9528763 12.37695,2.4921405 c 0.586415,-0.7539243 1.507854,-1.2146575 2.471219,-1.2146575 1.759136,0 3.183247,1.4240843 3.183247,3.1832473 v 0.1675393 c 0,0.8795788 -0.37695,1.7172755 -1.005236,2.3455507 L 16.020944,7.9790562 12,12 7.9790562,7.9790562 Z M 6.6387415,14.010472 h 0.3350787 9.0471238 1.005236 v 1.005236 1.968601 l 3.308915,-2.13614 0.879568,-0.54449 0.54449,0.879568 1.675393,2.68063 0.502618,0.795825 -0.795825,0.544489 -4.900512,3.350787 -0.251282,0.167539 H 17.696337 0.9423989 -0.062836 V 20.712045 H 0.9423989 17.361259 l 3.853404,-2.596886 -0.628286,-1.005236 -3.392658,2.13614 -0.20941,0.125668 H 16.691101 15.350787 13.340315 12.335079 10.659685 9.6544495 v -2.010472 h 1.0052355 1.675394 1.005236 1.675393 V 16.020944 H 7.2670274 l -3.769651,2.51309 -0.251309,0.167539 H 2.9528709 0.9423989 -0.062836 v -2.010472 h 1.0052349 1.6753933 l 3.7696135,-2.513089 z"
  {fill}
/>
