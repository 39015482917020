<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<g clip-path="url(#clip0_1786_8402)">
  <path
    d="M6.20062 21H1.84688V6.97971H6.20062V21ZM4.02141 5.06721C2.62922 5.06721 1.5 3.91408 1.5 2.52189C1.5 1.85318 1.76565 1.21185 2.2385 0.738991C2.71136 0.266136 3.35269 0.000488281 4.02141 0.000488281C4.69012 0.000488281 5.33145 0.266136 5.80431 0.738991C6.27716 1.21185 6.54281 1.85318 6.54281 2.52189C6.54281 3.91408 5.41313 5.06721 4.02141 5.06721ZM22.4953 21H18.1509V14.175C18.1509 12.5485 18.1181 10.4625 15.8873 10.4625C13.6237 10.4625 13.2769 12.2297 13.2769 14.0578V21H8.92781V6.97971H13.1034V8.89221H13.1644C13.7456 7.79064 15.1655 6.62814 17.2838 6.62814C21.69 6.62814 22.5 9.52971 22.5 13.2985V21H22.4953Z"
    {fill}
  />
</g>
<defs>
  <clipPath id="clip0_1786_8402">
    <rect width="21" height="24" fill="white" transform="translate(1.5)" />
  </clipPath>
</defs>
