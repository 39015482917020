<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M14.1188 3.0675L13.8451 3.9225L8.44509 20.7225L8.17134 21.5775L9.88509 22.1288L10.1588 21.2738L15.5588 4.47375L15.8326 3.61875L14.1188 3.0675ZM16.4288 8.14126L17.0851 8.75625L21.1838 12.6L17.0851 16.4438L16.4288 17.0588L17.6588 18.3713L18.3151 17.7563L23.1151 13.2563L23.8163 12.6L23.1151 11.9438L18.3151 7.44375L17.6588 6.82875L16.4288 8.14126ZM6.34134 6.82875L5.68509 7.44375L0.885088 11.9438L0.183838 12.6L0.885088 13.2563L5.68509 17.7563L6.34134 18.3713L7.57134 17.0588L6.91509 16.4438L2.81634 12.6L6.91509 8.75625L7.57134 8.14126L6.34134 6.82875Z"
  {fill}
/>
