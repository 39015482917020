<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M 6.666667,4.0000002 H 1.3333337 V 9.3333331 H 6.666667 V 7.3333339 6.0000001 Z m 1.333334,5.3333329 v 0.5 l 2.333333,3.5000009 h 0.333332 5.333333 1.333334 v 1.333334 5.333333 1.333332 H 15.999999 10.666666 9.333333 V 20.000001 14.666668 14.208333 L 6.958334,10.666666 H 6.666667 1.3333337 0 V 9.3333331 4.0000002 2.666667 H 1.3333337 6.666667 8.000001 v 1.3333332 2 h 7.999998 v -2 V 2.666667 H 17.333333 22.666666 24 V 4.0000002 9.3333331 10.666666 H 22.666666 17.333333 15.999999 V 9.3333331 7.3333339 H 8.000001 Z m 9.333332,0 h 5.333333 V 4.0000002 h -5.333333 v 2 1.3333338 z m -1.333334,5.3333349 h -5.333333 v 5.333333 h 5.333333 z"
  {fill}
/>
