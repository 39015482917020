<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M19.1719 6.42188L19.9688 5.625L18.375 4.03594L17.5781 4.83281L12 10.4109L6.42188 4.82812L5.625 4.03125L4.03594 5.625L4.83281 6.42188L10.4109 12L4.82812 17.5781L4.03125 18.375L5.625 19.9641L6.42188 19.1672L12 13.5891L17.5781 19.1719L18.375 19.9688L19.9641 18.375L19.1672 17.5781L13.5891 12L19.1719 6.42188Z"
  {fill}
/>
