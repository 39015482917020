<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M7.375 6.40625C6.84375 6.40625 6.375 6.875 6.375 7.40625C6.375 7.96875 6.84375 8.40625 7.375 8.40625C7.9375 8.40625 8.375 7.96875 8.375 7.40625C8.375 6.875 7.9375 6.40625 7.375 6.40625ZM7.375 15.625C6.84375 15.625 6.375 16.0625 6.375 16.625C6.375 17.1562 6.84375 17.625 7.375 17.625C7.9375 17.625 8.375 17.1562 8.375 16.625C8.375 16.0625 7.9375 15.625 7.375 15.625ZM12 17.5C11.4375 17.5 11 17.9688 11 18.5C11 19.0625 11.4375 19.5 12 19.5C12.5312 19.5 13 19.0625 13 18.5C13 17.9688 12.5312 17.5 12 17.5ZM5.5 11C4.9375 11 4.5 11.4688 4.5 12C4.5 12.5625 4.9375 13 5.5 13C6.03125 13 6.5 12.5625 6.5 12C6.5 11.4688 6.03125 11 5.5 11ZM12 4.5C11.4375 4.5 11 4.96875 11 5.5C11 6.0625 11.4375 6.5 12 6.5C12.5312 6.5 13 6.0625 13 5.5C13 4.96875 12.5312 4.5 12 4.5ZM18.5 11C17.9375 11 17.5 11.4688 17.5 12C17.5 12.5625 17.9375 13 18.5 13C19.0312 13 19.5 12.5625 19.5 12C19.5 11.4688 19.0312 11 18.5 11ZM16.5938 15.625C16.0312 15.625 15.5938 16.0625 15.5938 16.625C15.5938 17.1562 16.0312 17.625 16.5938 17.625C17.125 17.625 17.5938 17.1562 17.5938 16.625C17.5938 16.0625 17.125 15.625 16.5938 15.625Z"
  {fill}
/>
