<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<g clip-path="url(#clip0_1786_8362)">
  <path
    d="M1.5 10.125C1.5 7.01719 4.01719 4.5 7.125 4.5H7.875H9V6.75H7.875H7.125C5.25938 6.75 3.75 8.25938 3.75 10.125V10.5H8.25H10.5V12.75V17.25V19.5H8.25H3.75H1.5V17.25V15V12.75V10.5V10.125ZM3.75 12.75V15V17.25H8.25V12.75H3.75ZM20.25 12.75H15.75V15V17.25H20.25V12.75ZM13.5 15V12.75V10.5V10.125C13.5 7.01719 16.0172 4.5 19.125 4.5H19.875H21V6.75H19.875H19.125C17.2594 6.75 15.75 8.25938 15.75 10.125V10.5H20.25H22.5V12.75V17.25V19.5H20.25H15.75H13.5V17.25V15Z"
    {fill}
  />
</g>
<defs>
  <clipPath id="clip0_1786_8362">
    <rect width="21" height="24" fill="white" transform="translate(1.5)" />
  </clipPath>
</defs>
