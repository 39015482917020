<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="m 3.6,16.4 1.8,-0.9 3,-1.5 h 5.4 1.8 V 12.2 3.8 2 H 13.8 1.8 0 V 3.8 12.2 14 H 1.8 3.6 V 14.38624 15.8 Z M 7.593752,12.39128 5.4,13.48624 V 12.2 H 3.6 1.8 V 3.8 h 12 v 8.4 H 8.4 7.976248 L 7.597504,12.39128 Z M 9.6,17 v 1.8 h 1.8 4.2 l 3,1.5 1.8,0.9 V 20.6 19.18624 18.8 H 22.2 24 V 17 8.6 6.8 h -1.8 -5.4 v 1.8 h 5.4 V 17 h -1.8 -1.8 v 1.28624 L 16.40624,17.18752 16.02376,17 H 15.6 11.4 V 15.2 H 9.6 Z"
  {fill}
/>
