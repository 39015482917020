<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="m 1.500018,4.125004 c 0,-2.25 1.828124,-4.124998 4.125004,-4.124998 h 15.74996 1.125 v 1.124998 16.49999 1.125 h -1.125 -0.375 v 3 h 0.375 1.125 v 2.25 h -1.125 -16.12496 c -2.10938,0 -3.750004,-1.6406 -3.750004,-3.75 0,-0.0937 0,-0.2344 0,-0.375 z m 3.750004,14.62499 c -0.84375,0 -1.5,0.7031 -1.5,1.5 0,0.8438 0.65625,1.5 1.5,1.5 h 13.49996 v -3 z m -1.5,-1.9219 c 0.42187,-0.1875 0.9375,-0.3281 1.5,-0.3281 h 14.99996 V 2.250004 H 5.625022 c -1.07813,0 -1.875,0.84375 -1.875,1.875 z m 4.875,-11.57809 h 8.24996 1.125 v 2.25 h -1.125 -8.24996 -1.125 v -2.25 z m 0,3.75 h 8.24996 1.125 v 2.24999 h -1.125 -8.24996 -1.125 v -2.24999 z"
  {fill}
/>
