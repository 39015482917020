<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M4.2 5.00001H19.8V14H21.6V5.00001V3.20001H19.8H4.2H2.4V5.00001V14H4.2V5.00001ZM1.94625 17H22.0537L20.8537 18.2H3.14625L1.94625 17ZM24 17.6V17V15.2H22.2H1.8H0V17V17.6L2.4 20H21.6L24 17.6ZM10.5375 8.63751L11.175 8.00001L9.9 6.72876L9.2625 7.36626L7.4625 9.16627L6.825 9.80377L7.4625 10.4413L9.2625 12.2413L9.9 12.8788L11.1713 11.6L10.5338 10.9625L9.37125 9.80002L10.5338 8.63751H10.5375ZM14.7375 7.36251L14.1 6.72501L12.8288 8.00001L13.4663 8.63751L14.6288 9.80002L13.4663 10.9625L12.8288 11.6L14.1 12.8713L14.7375 12.2338L16.5375 10.4338L17.175 9.79626L16.5375 9.15876L14.7375 7.35876V7.36251Z"
  {fill}
/>
