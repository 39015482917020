<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  {fill}
  d="m 3.42857,2.5714301 c -0.4714301,0 -0.85714,0.38571 -0.85714,0.85714 V 20.57143 c 0,0.47143 0.38571,0.85714 0.85714,0.85714 h 17.14286 c 0.47143,0 0.85714,-0.38571 0.85714,-0.85714 V 3.4285701 c 0,-0.47143 -0.38571,-0.85714 -0.85714,-0.85714 z M 0,3.4285701 C 0,1.5375001 1.5374999,0 3.42857,0 H 20.57143 C 22.4625,0 24,1.5375001 24,3.4285701 V 20.57143 C 24,22.4625 22.4625,24 20.57143,24 H 3.42857 C 1.5374999,24 0,22.4625 0,20.57143 Z m 18.05357,6.05357 -6.85714,6.8571499 c -0.50357,0.50357 -1.3178602,0.50357 -1.8160699,0 L 5.9517799,12.91071 c -0.50357,-0.50357 -0.50357,-1.31785 0,-1.81607 0.50358,-0.49821 1.31786,-0.50357 1.81608,0 L 10.28571,13.6125 16.23214,7.6607101 c 0.50357,-0.50357 1.31786,-0.50357 1.81607,0 0.49822,0.50358 0.50357,1.31786 0,1.81608 z"
/>
