<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M 3.9999982,4.6666678 2.7916678,6.6666674 H 0 C 0,4.6666678 1.9999986,1.333335 6.6666698,1.333335 c 3.6666662,0 5.6666652,2.083333 6.3749852,3.916666 1.041653,-0.7083359 2.458347,-1.2499998 4.29168,-1.2499998 C 22,4.0000012 24,7.3333339 24,9.3333336 h -2.833333 l -1.166666,-1.9999997 -1.20832,1.9999997 h -4.499999 c 0.41664,1.2083194 0.749973,2.5416524 1,3.9166924 0.5,2.749973 0.708319,5.791626 0.333333,8.541652 l -0.08336,0.874987 H 14.666668 9.6666692 8.2916828 l 0.4166399,-1.29168 C 10.000003,17.208319 10.249976,14.166666 10.083309,12.083306 10.000003,11.458347 9.9166425,10.916693 9.8333359,10.5 L 4.0833316,16.208319 C 2.6666651,14.791679 1.7083346,11.041653 5.0416567,7.7499739 5.2083234,7.5833606 5.37499,7.4166406 5.5416566,7.2500273 Z m 8.1666708,4.6666658 h -0.58336 c 0.208373,0.7083194 0.37504,1.5833594 0.458346,2.5833594 0.20832,2.12496 0,5.041653 -1.041653,8.749972 h 2.749973 c 0.166667,-2.29168 0,-4.749973 -0.41664,-7.041652 -0.29168,-1.58336 -0.70832,-3.041653 -1.166666,-4.2916794 z"
  {fill}
/>
