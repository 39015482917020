<script lang="ts">
  import type { SVGAttributes } from 'svelte/elements';

  export let fill: SVGAttributes<SVGElement>['fill'];
</script>

<path
  d="M2.25 3.5625V8.0625H21.75V3.5625H2.25ZM0 1.3125H2.25H21.75H24V3.5625V8.0625V10.3125H21.75H2.25H0V8.0625V3.5625V1.3125ZM2.25 15.5625V20.0625H21.75V15.5625H2.25ZM0 13.3125H2.25H21.75H24V15.5625V20.0625V22.3125H21.75H2.25H0V20.0625V15.5625V13.3125ZM18.375 17.8125C18.375 17.2031 18.8437 16.6875 19.5 16.6875C20.1094 16.6875 20.625 17.2031 20.625 17.8125C20.625 18.4687 20.1094 18.9375 19.5 18.9375C18.8437 18.9375 18.375 18.4687 18.375 17.8125ZM19.5 4.6875C20.1094 4.6875 20.625 5.20312 20.625 5.8125C20.625 6.46875 20.1094 6.9375 19.5 6.9375C18.8437 6.9375 18.375 6.46875 18.375 5.8125C18.375 5.20312 18.8437 4.6875 19.5 4.6875ZM15.375 17.8125C15.375 17.2031 15.8437 16.6875 16.5 16.6875C17.1094 16.6875 17.625 17.2031 17.625 17.8125C17.625 18.4687 17.1094 18.9375 16.5 18.9375C15.8437 18.9375 15.375 18.4687 15.375 17.8125ZM16.5 4.6875C17.1094 4.6875 17.625 5.20312 17.625 5.8125C17.625 6.46875 17.1094 6.9375 16.5 6.9375C15.8437 6.9375 15.375 6.46875 15.375 5.8125C15.375 5.20312 15.8437 4.6875 16.5 4.6875Z"
  {fill}
/>
